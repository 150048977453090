<template>
  <login-card
    :name="appDisplayName"
    :logo="appLogoPrimary"
    :btn-label="$t('message.login')"
    :link-label="$t('common.cancel')"
    :loading="loading"
    class="two-fa-login-form"
    @click-btn="handleTwoFa"
    @click-link="handleClickLink"
  >
    <h1 class="headline mb-5">{{ $t('2fa.title') }}</h1>
    <p class="grey--text">
      {{ $t('2fa.enterCodePromptText') }}
    </p>
    <div class="two-fa-login-form__input" :class="{ 'two-fa-login-form__input--has-errors': invalidCodeError }">
      <pincode-input
        data-test="pincode-input"
        ref="pincode"
        v-model="code"
        :length="codeLength"
        @input="handleCodeInput"
      />
      <span v-if="invalidCodeError" class="two-fa-login-form__error">{{ invalidCodeText }}</span>
    </div>
    <!-- help -->
    <div class="d-flex flex-row justify-space-between">
      <v-btn
        data-test="login-help-link"
        color="primary"
        small :href="$t('2fa.loginHelpLink')"
        target="_blank"
        text
        class="mt-4 mb-8 px-1 mr-1"
      >
        {{ $t('2fa.loginHelpLinkText') }}
      </v-btn>

      <v-btn
        data-test="request-reset-2fa"
        color="primary"
        small
        target="_blank"
        text
        class="mt-4 mb-8 px-1 ml-1"
        :loading="requestingReset2fa"
        :disabled="requestingReset2fa"
        @click="handleRequest2fa"
      >
        {{ $t('2fa.requestReset') }}
      </v-btn>
    </div>
  </login-card>
</template>

<script>
import { mapState } from 'vuex';
import PincodeInput from 'vue-pincode-input';
import LoginCard from '@/components/Login/LoginCard.vue';
import { TWO_FA_CODE_LENGTH } from '@/config/constants';
import { use2FA } from '@/use/2FA/use2FA';

export default {
  name: 'LoginFormTwoFa',

  components: {
    LoginCard,
    PincodeInput,
  },

  props: {
    invalidCodeError: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    username: {
      type: String,
      default: null,
    },
  },

  setup() {
    const { requestingReset2fa, isNetworkError, alreadyRequestedReset, requestReset2fa, sentRequestReset2fa } = use2FA();

    return {
      requestingReset2fa,
      isNetworkError,
      alreadyRequestedReset,
      sentRequestReset2fa,
      requestReset2fa,
    };
  },

  data() {
    return {
      code: '',
      codeLength: TWO_FA_CODE_LENGTH,
      invalidCodeText: this.$t('2fa.invalidCodeError'),
    };
  },

  computed: {
    ...mapState({
      appName: state => state.app.name,
      appDisplayName: state => state.app.displayName,
      appLogoPrimary: state => state.app.images.logoPrimary,
    }),
  },

  watch: {
    invalidCodeError(val) {
      !!val && (this.code = '');
    },

    sentRequestReset2fa(val) {
      if (val) {
        this.$toast.success(this.$t('2fa.sentRequestResetToAdmin'));
      }
    },

    isNetworkError(val) {
      if (val) {
        this.$toast.error(this.$t('2fa.failedResetRequest'));
      }
    },

    alreadyRequestedReset(val) {
      if (val) {
        this.$toast.warning(this.$t('2fa.alreadyRequestedReset'));
      }
    },
  },

  methods: {
    handleTwoFa() {
      if (!this.code) {
        return;
      }

      this.$emit('handle-two-fa', this.code);
    },

    handleClickLink() {
      this.$emit('click-link');
    },

    handleCodeInput(val) {
      val && this.$emit('reset-error');
      if (this.code.length < this.codeLength) return;
    },
    async handleRequest2fa() {
      await this.requestReset2fa(this.username);
      this.handleClickLink();
    },
  },
};
</script>

<style lang="scss" scoped>
.two-fa-login-form {
  &__input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ::v-deep input {
      @extend %two-fa-input !optional;
    }

    &--has-errors {
      ::v-deep input {
        border-color: #ff5454;
      }
    }
  }

  &__error {
    color: #ff5454;
  }
}
</style>
