<template>
  <header
    v-if="accountHeaderData"
    class="account-header"
    :class="{ 'account-header--edit-mode': editMode, 'account-header--is-editable': canEdit }"
  >
    <d-avatar
      :text="`${accountHeaderData.name} ${accountHeaderData.surname}`"
      size="110"
      :can-edit="isProfessionalRole"
      rounded
      two-letter
      :src="accountHeaderData.image"
      :loading="loadingAvatar"
      class="account-header__avatar mb-2"
      @handle-avatar="handleAvatar"
    />
    <template>
      <v-form ref="headerDataForm" v-model="validForm" lazy-validation class="account-header__form">
        <!-- name + surname -->
        <h1 v-if="name || surname" class="account-header__title">
          <template v-if="editMode && canEdit">
            <v-text-field
              v-model="name"
              :label="`${$t('patient.first_name')}*`"
              dense
              outlined
              :disabled="disabledField"
              :rules="rules.nameOrSurname"
              class="account-header__input mr-2"
              @input="handleName"
            />
            <v-text-field
              v-model="surname"
              :label="`${$t('patient.last_name')}*`"
              dense
              outlined
              :disabled="disabledField"
              :rules="rules.nameOrSurname"
              class="account-header__input"
              @input="handleSurname"
            />
          </template>
          <template v-else>{{ name }} {{ surname }}</template>
        </h1>
        <!-- specialties -->
        <template v-if="accountHeaderData.specialties">
          <h2 class="account-header__subtitle">{{ accountHeaderData.specialties }}</h2>
        </template>
        <p class="account-header__info">
          <!-- collegiateNumber -->
          <span v-if="accountHeaderData.collegiateNumber">
            {{ $t('user.num_colegiado') }}: {{ accountHeaderData.collegiateNumber }}
          </span>
          <!-- idDocument -->
          <span v-if="accountHeaderData.idDocument">
            <template v-if="editMode && canEdit">
              <v-text-field
                v-model="dni"
                :label="$t('account.idPassport')"
                dense
                outlined
                :disabled="disabledField"
                :rules="rules.validId"
                class="account-header__input"
                @input="handleDni"
              />
            </template>
            <template v-else> {{ $t('account.idPassport') }}: {{ dni }} </template>
          </span>
          <!-- email, not editable -->
          <span v-if="accountHeaderData.email">
            <template v-if="editMode && canEdit">
              <v-text-field
                v-model="accountHeaderData.email"
                :label="$t('message.email')"
                dense
                outlined
                disabled
                class="account-header__input"
              />
            </template>
            <template v-else> {{ $t('message.email') }}: {{ accountHeaderData.email }} </template>
          </span>
        </p>
      </v-form>
    </template>
  </header>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import FormValidation from '@/mixins/FormValidation';
import DAvatar from '@/components/DAvatar.vue';

export default {
  name: 'AccountHeader',

  components: {
    DAvatar,
  },

  mixins: [FormValidation],

  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    componentKey: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      loadingAvatar: false,
      name: '',
      surname: '',
      dni: '',
      nameError: '',
      surnameError: '',
      validForm: true,
    };
  },

  computed: {
    ...mapState('account', {
      editMode: 'editMode',
    }),

    ...mapGetters({
      accountHeaderData: 'account/getHeaderData',
      isProfessionalRole: 'account/isProfessionalRole',
    }),

    disabledField() {
      return !(this.canEdit && this.editMode);
    },
  },

  watch: {
    accountHeaderData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val) return;
        this.setDefaultValues(val);
      },
    },
    editMode(val) {
      val && this.emitNewValues();
    },
    componentKey() {
      this.setDefaultValues(this.accountHeaderData);
    },
  },

  methods: {
    ...mapActions({
      handleAvatarUpload: 'account/handleAvatarUpload',
    }),

    setDefaultValues(values) {
      const { name = '', surname = '' } = values;

      this.name = name;
      this.surname = surname;
      this.dni = this.accountHeaderData.idDocument;
    },

    async handleAvatar(image) {
      this.loadingAvatar = true;

      const successUpload = await this.handleAvatarUpload(image);
      !successUpload && this.$toast.error(this.$t('hiring.apiError'));

      this.loadingAvatar = false;
    },

    handleName(value) {
      this.emitNewValues({ key: 'name', value });
    },

    handleSurname(value) {
      this.emitNewValues({ key: 'surname', value });
    },

    handleDni(value) {
      this.emitNewValues({ key: 'dni', value });
    },

    emitNewValues({ key = null, value = null } = {}) {
      if (key && value) {
        this[key] = value;
      }
      const isValid = this.$refs.headerDataForm.validate();

      this.$emit('handle-header-data', {
        isValid,
        name: this.name,
        surname: this.surname,
        dni: this.dni,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.account-header {
  $--self: &;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__avatar {
    align-self: center;
  }

  &__title,
  &__subtitle {
    font-weight: 400;
  }

  &__subtitle,
  &__info {
    color: rgba($color: #000000, $alpha: 0.6);
    margin-top: rem(8);
    text-align: center;
  }

  &__title {
    font-size: rem(24);
    margin-top: rem(16);
    display: flex;
    justify-content: center;
  }

  &__subtitle {
    font-size: rem(16);
  }

  &__info {
    width: 100%;
    max-width: 100%;
    font-size: rem(14);

    @include breakpoint(md) {
      width: rem(460);
    }

    span {
      display: inline-block;

      &:not(:first-child) {
        margin-left: rem(16);
      }
    }
  }

  .input-as-text__input--active {
    text-align: center !important;
  }

  &--edit-mode.account-header--is-editable {
    align-items: flex-start;

    #{$--self}__form {
      width: calc(100% - 80px);
      margin: 0 auto;
    }

    #{$--self}__input {
      width: 50%;
    }

    #{$--self}__info {
      width: 100%;
      display: flex;
      text-align: left;

      span {
        width: 50%;

        #{$--self}__input {
          width: 100%;
        }
      }
    }
  }
}
</style>
