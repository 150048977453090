<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-dialog
    v-model="show"
    scrollable
    max-width="640"
    class="fa-sms-dialog"
    @click:outside="handleCancel"
    @keydown.esc="handleCancel"
    @keydown.enter="handleAccept"
  >
    <v-card class="px-6 py-6">
      <!-- title -->
      <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0">
        {{ title }}
        <v-spacer />
        <v-btn v-tooltip="$t('common.close')" class="fa-sms-dialog__close-button" icon @click="handleCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- body -->
      <p class="text-body-1 text--secondary" v-html="text" />
      <v-tel-field
        v-model="phone"
        outlined
        dense
        filled
        background-color="white"
        :rules="rules.phone"
        v-bind="phoneFieldProps"
        @input="handlePhone"
      />
      <!-- actions -->
      <v-card-actions class="mt-12">
        <action-buttons
          :accept-text="$t('2fa.smsModalButton')"
          :cancel-text="$t('common.cancel')"
          @on-click:accept="handleAccept"
          @on-click:cancel="handleCancel"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defaultProps } from '@/modules/VuetifyTelField/utils';
import ActionButtons from '@/components/ui/ActionButtons';
import FormValidator from '@/mixins/FormValidation';
import { mapActions } from 'vuex';

export default {
  name: 'TwoFaSendSmsDialog',

  components: {
    ActionButtons,
  },

  mixins: [FormValidator],

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default() {
        return this.$t('2fa.smsModalTitle');
      },
    },
    text: {
      type: String,
      default() {
        return this.$t('2fa.smsModalText');
      },
    },
  },

  data() {
    return {
      phoneFieldProps: defaultProps(this),
      phone: '',
      fullPhone: '',
    };
  },

  methods: {
    ...mapActions({
      sendSms: 'account/sendSms',
    }),

    async handleAccept() {
      const { success, status } = await this.sendSms(this.fullPhone);
      const msg = success ? '2fa.smsSent' : status === 429 ? '2fa.smsMaxAllowed' : '2fa.smsSendingError';

      this.$toast[success ? 'success' : 'error'](this.$t(msg, { phone: this.fullPhone }));
      this.$emit('close');
    },

    handleCancel() {
      this.$emit('close');
    },

    handlePhone(_, { number = null, country = null } = {}) {
      const { dialCode: prefix } = country || {};
      const { input: phone } = number || {};

      this.fullPhone = `+${prefix}${phone}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fa-sms-dialog {
  &__close-button {
    position: absolute;
    top: rem(4);
    right: rem(4);

    @include breakpoint(md) {
      position: relative;
    }
  }
}
</style>
