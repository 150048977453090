<template>
  <v-main class="signup">
    <v-app-bar v-if="showForm" app color="primary" fixed dark clipped-left elevate-on-scroll>
      <router-link to="/" class="ml-2 mr-3 hidden-sm-and-down logo-width">
        <img v-if="!isDocline" :src="appLogoSecondary" class="img-toolbar" />
        <d-logo
          v-if="isDocline"
          :slogan="false"
          primary-color="#ffffff"
          secondary-color="#ffffff"
          height="28"
          class="d-flex"
        />
      </router-link>
    </v-app-bar>
    <div v-if="showForm" class="text-center bg-gradient">
      <transition name="component-fade" mode="out-in">
        <signup-selector v-show="show.selector" @selectForm="selectForm" />
      </transition>
      <transition name="component-fade" mode="out-in">
        <signup-patient-form v-show="show.patient" @success="success" />
      </transition>
      <transition name="component-fade" mode="out-in">
        <signup-professional-form v-show="show.professional" />
      </transition>
      <transition name="component-fade" mode="out-in">
        <signup-success v-show="show.signUpSuccess" />
      </transition>
    </div>
  </v-main>
</template>

<script>
import { mapState } from 'vuex';
import environment from '@/environment';
import DLogo from '@/components/DLogo.vue';
import SignupPatientForm from '../components/Signup/SignupPatientForm.vue';
import SignupSelector from '../components/Signup/SignupSelector.vue';
import SignupProfessionalForm from '../components/Signup/SignupProfessionalForm.vue';
import SignupSuccess from '../components/Signup/SignupSuccess.vue';

export default {
  components: { SignupSuccess, SignupProfessionalForm, SignupSelector, SignupPatientForm, DLogo },
  data() {
    return {
      show: {
        selector: true,
        patient: false,
        professional: false,
        signUpSuccess: false,
      },
      showForm: false,
    };
  },
  computed: {
    toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },

    ...mapState({
      appIntercomId: state => state.app.intercom.id,
      appIntercomUserMaps: state => state.app.intercom.userMaps,
      appLogoSecondary: state => state.app.images.logoSecondary,
      appMenuLogoBackground: state => state.app.styles.menuLogoBackground,
      appMenuLogoClass: state => state.app.styles.menuLogoClass,
      appThemePrimary: state => state.app.styles.themePrimary,
      appThemeSecondary: state => state.app.styles.themeSecondary,
      appName: state => state.app.name,
    }),

    isDocline() {
      //TODO:Crear mejor metodo
      return /^docline/.test(this.appName);
    },
  },

  mounted() {
    this.setViewFormSignup();
  },

  methods: {
    selectForm(type) {
      this.show.selector = false;
      if (type === 'patient') {
        this.show.patient = true;
      } else {
        this.show.professional = true;
      }
    },

    success(status) {
      if (status) {
        this.show.patient = false;
        this.show.professional = false;
        this.show.signUpSuccess = true;
      }
    },

    setViewFormSignup() {
      if (environment.signUpAs.patient && environment.signUpAs.professional) {
        this.show = {
          selector: true,
          patient: false,
          professional: false,
          signUpSuccess: false,
        };
      } else if (environment.signUpAs.patient) {
        this.show = {
          selector: false,
          patient: true,
          professional: false,
          signUpSuccess: false,
        };
      } else if (environment.signUpAs.professional) {
        this.show = {
          selector: false,
          patient: false,
          professional: true,
          signUpSuccess: false,
        };
      }

      this.showForm = true;
    },
  },

  beforeRouteLeave(to, from, next) {
    this.setViewFormSignup();
    next();
  },
};
</script>

<style scoped lang="css">
.signup {
  max-width: 1080px;
  margin: 0 auto !important;
  float: none !important;
  padding: 20px;
}
.bg-gradient {
  background: var(--v-secondary-base);
  background: linear-gradient(114deg, var(--v-primary-base) 20%, var(--v-secondary-base) 100%);
  border-radius: 10px;
  padding: 0px 40px !important;
  max-width: 790px;
  margin-top: 100px;
}
.component-fade-enter-active {
  transition: opacity 0.3s ease;
}
.component-fade-leave-active {
  transition: opacity 0.15s ease;
}
.component-fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.img-toolbar {
  object-fit: contain;
  vertical-align: middle;
  max-height: 48px;
  max-width: 176px;
  width: auto;
  height: auto;
}

.logo-width {
  width: 160px;
}
</style>
