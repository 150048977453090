<template>
  <div class="two-fa-download-app">
    <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0">
      {{ $t('2fa.downloadApp') }}
    </v-card-title>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="text-body-1 text--secondary" v-html="$t('2fa.downloadAppText')" />
    <!-- stores -->
    <div v-if="isAndroidOrIos" class="d-flex mt-6 align-center two-fa-download-app__google-logo">
      <figure>
        <component :is="googleAuthenticatorLogo" alt="Google Authenticator en App Store" />
      </figure>
      <p class="d-flex flex-column my-0 ml-4">
        <span class="pl-3">{{ $t('2fa.googleAppName') }}</span>
        <v-btn :href="getMobileStoreLink" text color="primary" class="px-0">
          {{ $t('2fa.downloadApp') }}
        </v-btn>
      </p>
    </div>
    <div v-else class="d-flex mt-8">
      <figure class="mr-6">
        <a :href="googlePlayLink" target="_blank">
          <component :is="storeImg('google-play')" alt="Google Authenticator en Play Store" />
        </a>
      </figure>
      <figure>
        <a :href="appStoreLink" target="_blank">
          <component :is="storeImg('app-store')" alt="Google Authenticator en App Store" />
        </a>
      </figure>
    </div>
    <div v-show="smsServiceActive" class="two-fa-download-app__sms-link mt-6">
      <p role="button" class="primary--text my-0 text-body-1" @click="handleSmsDialog">{{ $t('2fa.smsLinkText') }}</p>
      <two-fa-send-sms-dialog :show="showSmsDialog" @close="showSmsDialog = false" />
    </div>

    <!-- actions -->
    <v-card-actions class="mt-16">
      <action-buttons
        :accept-text="$t('2fa.alreadyInstalled')"
        @on-click:accept="handleAccept"
        @on-click:cancel="handleCancel"
      />
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionButtons from '@/components/ui/ActionButtons';
import TwoFaSendSmsDialog from '@/components/2fa/TwoFaSendSmsDialog';
import { GOOGLE_AUTHENTICATOR_PLAY_STORE_LINK, GOOGLE_AUTHENTICATOR_APP_STORE_LINK } from '@/config/constants';

export default {
  name: 'AccountTwoFaStepDownload',

  components: {
    ActionButtons,
    TwoFaSendSmsDialog,
  },

  data() {
    return {
      googlePlayLink: GOOGLE_AUTHENTICATOR_PLAY_STORE_LINK,
      appStoreLink: GOOGLE_AUTHENTICATOR_APP_STORE_LINK,
      showSmsDialog: false,
      smsServiceActive: false,
    };
  },

  computed: {
    ...mapGetters({
      isAndroidOrIos: 'app/isAndroidOrIos',
    }),

    storeImg() {
      let locale = this.$i18n.locale;
      locale = locale === 'en' || locale === 'es' ? locale : 'en';

      return store => {
        try {
          return require(`@/assets/images/${store}-badge-${locale}.svg`);
        } catch (e) {
          return null;
        }
      };
    },

    getMobileStoreLink() {
      return this.$device.isIOS ? this.appStoreLink : this.googlePlayLink;
    },

    googleAuthenticatorLogo() {
      try {
        return require(`@/assets/images/google-autenticator-app.svg`);
      } catch (e) {
        return null;
      }
    },
  },
  created() {
    this.smsServiceActive = JSON.parse(window.localStorage.getItem('smsNotificationsEnabled'));
  },
  methods: {
    handleAccept() {
      this.$emit('accept');
    },

    handleCancel() {
      this.$router.push({ name: 'AccountMainView' });
    },

    handleSmsDialog() {
      this.showSmsDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.two-fa-download-app {
  &__google-logo {
    padding: 8px 16px;
    background: #fafafa;
    border-radius: 8px;
    min-height: 130px;
  }
}
</style>
