<template>
  <section>
    <v-card elevation="2" class="account-page__card mt-12">
      <account-two-fa-setup />
    </v-card>
  </section>
</template>

<script>
import AccountTwoFaSetup from '@/components/account/layout/AccountTwoFaSetup';

export default {
  name: 'Account2FAView',

  components: {
    AccountTwoFaSetup,
  },
};
</script>
