<template>
  <v-layout>
    <v-flex xs6>
      <v-card class="mr-3 mb-5 card-signup-selector">
        <v-card-text>
          <img class="pt-5" src="/static/patient.svg" alt="patient" />
          <h2 class="headline pt-2 mb-3 black--text">{{ $t('signup.i_am_patient') }}</h2>
          <h3 class="mb-2 selector-subtitle">{{ $t('signup.selector_patient_text') }}</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn rounded depressed large color="primary" class="px-5" @click="$emit('selectForm', 'patient')">
            {{ $t('message.start') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
        <v-card-actions class="pt-3 pb-12">
          <v-spacer>
            <a class="link" href="#" @click="goToLogin">{{ $t('signup.do_you_already_have_an_account') }}</a>
          </v-spacer>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs6>
      <v-card class="ml-3 mb-5 card-signup-selector">
        <v-card-text>
          <img class="pt-5" src="/static/cross.svg" alt="professional" />
          <h2 class="headline pt-2 mb-3 black--text">{{ $t('signup.i_am_professional') }}</h2>
          <h3 class="mb-2 selector-subtitle">{{ $t('signup.selector_professional_text') }}</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn rounded depressed large color="primary" class="px-5" @click="$emit('selectForm', 'professional')">
            {{ $t('message.start') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
        <v-card-actions class="pt-3 pb-12">
          <v-spacer>
            <a class="link" href="#" @click="goToLogin">{{ $t('signup.do_you_already_have_an_account') }}</a>
          </v-spacer>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import environment from '@/environment';

export default {
  name: 'SignupSelector',
  data() {
    return {
      displayName: environment.displayName,
      colorPrimary: this.$vuetify.theme.primary + 'eb',
    };
  },
  computed: {
    ...mapState({
      appLoginBackground: state => state.app.images.loginBackground,
    }),
    minDateOfBirth() {
      return this.getDateAgo(65);
    },
    maxDateOfBirth() {
      return this.getDateAgo(18);
    },
    currentLanguageName() {
      const found = this.availableLanguages.find(item => item.lang === this.$i18n.locale);
      return found.title;
    },
  },
  methods: {
    goToLogin() {
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped>
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
}
.rgpd a {
  padding-left: 10px;
}

.card-signup-selector {
  margin-top: -32px;
  min-height: 360px;
}
.link {
  text-decoration: none;
  font-size: 14px;
}
.selector-subtitle {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.headline {
  font-size: 21px !important;
}
</style>
