<template>
  <div class="two-fa-intro">
    <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0">
      {{ $t('2fa.title') }}
    </v-card-title>
    <figure class="text-center two-fa-intro__image">
      <component :is="src" alt="Two Factor Auth" />
    </figure>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="text-body-1 text--secondary" v-html="$t('2fa.introText')" />
    <v-card-actions class="mt-16">
      <action-buttons :accept-text="$t('2fa.setup')" @on-click:accept="handleAccept" @on-click:cancel="handleCancel" />
    </v-card-actions>
  </div>
</template>

<script>
import ActionButtons from '@/components/ui/ActionButtons';

export default {
  name: 'AccountTwoFaIntro',

  components: {
    ActionButtons,
  },

  computed: {
    src() {
      try {
        return require('@/assets/images/illustration-2fa.svg');
      } catch (e) {
        return null;
      }
    },
  },

  methods: {
    handleAccept() {
      this.$emit('accept');
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.two-fa-intro {
  &__image {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;

    @include breakpoint(md) {
      width: rem(280);
    }
  }
}
</style>
