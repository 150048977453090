<template>
  <div>
    <v-form ref="changePasswordForm">
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="changePasswordModel.currentPassword"
            :rules="currentPasswordRules"
            outlined
            dense
            validate-on-blur
            :placeholder="$t('change_password.current_password')"
            :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showCurrentPassword ? 'text' : 'password'"
            @click:append="showCurrentPassword = !showCurrentPassword"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="changePasswordModel.newPassword"
            :rules="newPasswordRules"
            outlined
            dense
            validate-on-blur
            :placeholder="$t('change_password.new_password')"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showNewPassword ? 'text' : 'password'"
            @click:append="showNewPassword = !showNewPassword"
          />
          <ul
            v-if="changePasswordModel.newPassword.length > 0"
            class="password-restrictions-list text-caption mb-4 mb-md-0"
            :class="$vuetify.breakpoint.mdAndUp ? 'restrictions-disposition' : ''"
          >
            <li v-if="minLength > 0">
              <v-icon v-if="complyWithMinimumLength" color="success" small>mdi-check</v-icon>
              <v-icon v-else color="error" small>mdi-close</v-icon>
              {{ $t('change_password.at_least_num_characters', { num: minLength }) }}
            </li>
            <li v-if="mustContainLowercase">
              <v-icon v-if="complyWithLowercase" color="success" small>mdi-check</v-icon>
              <v-icon v-else color="error" small>mdi-close</v-icon>
              {{ $t('change_password.at_least_one_lowercase') }}
            </li>
            <li v-if="mustContainUppercase">
              <v-icon v-if="complyWithUppercase" color="success" small>mdi-check</v-icon>
              <v-icon v-else color="error" small>mdi-close</v-icon>
              {{ $t('change_password.at_least_one_uppercase') }}
            </li>
            <li v-if="mustContainNumbers">
              <v-icon v-if="complyWithNumbers" color="success" small>mdi-check</v-icon>
              <v-icon v-else color="error" small>mdi-close</v-icon>
              {{ $t('change_password.at_least_one_number') }}
            </li>
            <li v-if="mustContainSymbols">
              <v-icon v-if="complyWithSymbols" color="success" small>mdi-check</v-icon>
              <v-icon v-else color="error" small>mdi-close</v-icon>
              {{ $t('change_password.at_least_one_symbol') }}
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="changePasswordModel.confirmPassword"
            :rules="confirmPasswordRules"
            :placeholder="$t('change_password.confirm_password')"
            outlined
            dense
            validate-on-blur
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append="showConfirmPassword = !showConfirmPassword"
          />
        </v-col>
      </v-row>
      <v-row justify="end" class="pr-1" dense>
        <v-btn v-if="showCancelButton" outlined color="primary" class="mr-4" @click="handleCancel">
          {{ cancelButtonText }}
        </v-btn>
        <v-btn depressed class="primary" @click="save">
          {{ $t('common.save') }}
        </v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { changePasswordUrl, getHeader } from '@/config/config';

export default {
  name: 'ChangePasswordForm',
  props: {
    userId: {
      type: [Number, String],
      required: true,
    },
    minLength: {
      type: [Number, String],
      default: 8,
    },
    mustContainLowercase: {
      type: Boolean,
      default: true,
    },
    mustContainUppercase: {
      type: Boolean,
      default: true,
    },
    mustContainNumbers: {
      type: Boolean,
      default: true,
    },
    mustContainSymbols: {
      type: Boolean,
      default: true,
    },
    showCancelButton: {
      type: Boolean,
      default: false,
    },
    cancelButtonText: {
      type: String,
      default() {
        return this.$t('common.cancel');
      },
    },
  },
  data() {
    return {
      changePasswordModel: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      currentPasswordRules: [v => !!v || this.$t('change_password.password_required_message')],
      newPasswordRules: [
        v => !!v || this.$t('change_password.password_required_message'),
        () => !!this.newPasswordIsValid || this.$t('change_password.password_not_valid_message'),
      ],
      confirmPasswordRules: [
        v => !!v || this.$t('change_password.password_required_message'),
        v => v === this.changePasswordModel.newPassword || this.$t('change_password.passwords_not_match'),
      ],
    };
  },
  computed: {
    complyWithMinimumLength() {
      return this.minLength ? this.changePasswordModel.newPassword.length >= this.minLength : true;
    },
    complyWithLowercase() {
      return this.mustContainLowercase ? this.changePasswordModel.newPassword.match(/[a-z]/) : true;
    },
    complyWithUppercase() {
      return this.mustContainUppercase ? this.changePasswordModel.newPassword.match(/[A-Z]/) : true;
    },
    complyWithNumbers() {
      return this.mustContainNumbers ? this.changePasswordModel.newPassword.match(/\d/) : true;
    },
    complyWithSymbols() {
      return this.mustContainSymbols ? this.changePasswordModel.newPassword.match(/[\W]/) : true;
    },
    newPasswordIsValid() {
      return (
        this.complyWithMinimumLength &&
        this.complyWithLowercase &&
        this.complyWithUppercase &&
        this.complyWithNumbers &&
        this.complyWithSymbols
      );
    },
  },
  methods: {
    async save() {
      const isValid = this.$refs.changePasswordForm.validate();
      if (!isValid) return;

      try {
        const formData = {
          currentPassword: this.changePasswordModel.currentPassword,
          newPassword: this.changePasswordModel.newPassword,
        };

        await this.$http.put(`${changePasswordUrl}/${this.userId}`, formData, {
          headers: getHeader(),
        });

        this.$emit('on-success', this.$t('change_password.change_password_success_message'));
      } catch (err) {
        const { status } = err || {};
        const message =
          status === 400
            ? this.$t('change_password.current_password_not_valid')
            : this.$t('change_password.unknown_error');

        this.$emit('on-error', message);
      }
    },

    handleCancel() {
      this.$emit('on-cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.password-restrictions-list {
  padding: 0;
  list-style-type: none;

  &.restrictions-disposition {
    column-count: 2;
    column-gap: 8px;
  }
}
</style>
