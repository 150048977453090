<template>
  <section class="account-biography">
    <!-- bio -->
    <h3 class="account-biography__title">{{ $t('account.biography') }}</h3>
    <!-- es -->
    <template v-if="locale === 'es' || editMode">
      <span v-if="editMode" class="account-biography__label">{{
        $t('account.editBio', { lang: getLang('spanish') })
      }}</span>
      <v-textarea
        class="account-biography__textarea"
        :class="{ 'account-biography__textarea--edit-mode': editMode }"
        :disabled="!editMode"
        :value="bioEs"
        auto-grow
        :solo="!editMode"
        :outlined="editMode"
        flat
        :rows="1"
        @input="handleTextareaEs"
      />
    </template>
    <!-- en -->
    <template v-if="locale === 'en' || editMode">
      <span v-if="editMode" class="account-biography__label">{{
        $t('account.editBio', { lang: getLang('english') })
      }}</span>
      <v-textarea
        class="account-biography__textarea"
        :class="{ 'account-biography__textarea--edit-mode': editMode }"
        :disabled="!editMode"
        :value="bioEn"
        auto-grow
        :solo="!editMode"
        :outlined="editMode"
        flat
        :rows="1"
        @input="handleTextareaEn"
      />
    </template>
  </section>
</template>

<script>
export default {
  name: 'AccountBiography',

  props: {
    bioEn: {
      type: String,
      default: '',
    },

    bioEs: {
      type: String,
      default: '',
    },

    locale: {
      type: String,
      required: true,
    },

    editMode: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    handleTextareaEs(value) {
      this.$emit('handle-textarea-es', value);
    },

    handleTextareaEn(value) {
      this.$emit('handle-textarea-en', value);
    },

    getLang(lang) {
      return this.$i18n.messages[this.locale].common[[lang]].toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.account-biography {
  &__title {
    font-size: rem(14);
    font-weight: 400;
    margin-bottom: rem(8);
  }
  &__textarea {
    &::v-deep.v-input--is-disabled textarea {
      color: rgba($color: #000000, $alpha: 0.6);
    }

    &::v-deep.v-input textarea {
      margin-top: 0 !important;
    }

    &:not(&--edit-mode) {
      &::v-deep .v-input__slot {
        padding: 0 !important;
        margin: 0;
      }
    }
  }

  &__label {
    color: rgba($color: #000000, $alpha: 0.6);
    font-size: rem(12);
  }
}
</style>
