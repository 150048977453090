<template>
  <v-layout align-center justify-center>
    <v-card align-center justify-center class="px-5 py-10" width="600px">
      <div style="text-align: center;">
        <img v-if="showIcon" src="/static/ilustracion-incompatibilidad.svg" alt="Incompatible Browser" />
        <p class="migration-title px-sm-2 px-0 pt-7">{{ titleComputed }}</p>
        <p class="migration-text px-sm-15 px-2">
          {{ messageComputed }}
        </p>
        <p class="migration-title px-sm-2 px-0 pt-7">{{ title2Computed }}</p>
        <p class="migration-text px-sm-15 px-2">
          {{ message2Computed }}
        </p>
      </div>
      <v-alert v-if="showHelpCenter" border="left" color="#2196f3" dark icon="mdi-lifebuoy">
        {{ helpCenterMessageComputed }}
        <a class="white--text" :href="helpCenterLinkComputed" target="_blank">
          {{ helpCenterLinkNameComputed }}
        </a>
      </v-alert>
    </v-card>
  </v-layout>
</template>

<script>
export default {
  props: {
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    title2: {
      type: String,
      required: false,
      default: '',
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
    message2: {
      type: String,
      required: false,
      default: '',
    },
    showHelpCenter: {
      type: Boolean,
      required: false,
      default: true,
    },
    helpCenterLink: {
      type: String,
      required: false,
      default: '',
    },
    helpCenterMessage: {
      type: String,
      required: false,
      default: '',
    },
    helpCenterLinkName: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    titleComputed() {
      return this.title !== '' ? this.title : this.$t('incompatibleBrowser.title');
    },
    messageComputed() {
      return this.message !== '' ? this.message : this.$t('incompatibleBrowser.message');
    },
    title2Computed() {
      return this.title2 !== '' ? this.title2 : this.$t('incompatibleBrowser.title2');
    },
    message2Computed() {
      return this.message2 !== '' ? this.message2 : this.$t('incompatibleBrowser.message2');
    },
    helpCenterMessageComputed() {
      return this.helpCenterMessage !== '' ? this.helpCenterMessage : this.$t('incompatibleBrowser.helpCenterMessage');
    },
    helpCenterLinkComputed() {
      return this.helpCenterLink !== '' ? this.helpCenterLink : this.$t('incompatibleBrowser.helpCenterLink');
    },
    helpCenterLinkNameComputed() {
      return this.helpCenterLinkName !== ''
        ? this.helpCenterLinkName
        : this.$t('incompatibleBrowser.helpCenterLinkName');
    },
  },
};
</script>

<style>
.migration-title {
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
}
.migration-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #747474;
  margin-bottom: 24px !important;
}
.migration-text a {
  text-decoration: none;
}
</style>
