<template>
  <v-layout>
    <v-flex xs12>
      <v-card class="mr-3 mb-5 card-signup-selector">
        <v-card-text>
          <h2 class="headline pt-5">{{ $t('signup.signup_successfully') }}</h2>
          <v-icon size="80" color="green lighten-1">mdi-check</v-icon>
          <h3 class="mb-8 font-weight-light" style="max-width: 450px">{{ $t('signup.check_your_inbox') }}</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn rounded depressed large color="primary" to="/" class="px-5">
            {{ $t('message.login') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import environment from '@/environment';

export default {
  name: 'SignupSuccess',
  data() {
    return {
      displayName: environment.displayName,
      colorPrimary: this.$vuetify.theme.primary + 'eb',
    };
  },
  computed: {
    ...mapState({
      appLoginBackground: state => state.app.images.loginBackground,
    }),
    minDateOfBirth() {
      return this.getDateAgo(65);
    },
    maxDateOfBirth() {
      return this.getDateAgo(18);
    },
    currentLanguageName() {
      const found = this.availableLanguages.find(item => item.lang === this.$i18n.locale);
      return found.title;
    },
  },
  methods: {
    goToLogin() {
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped>
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
}
.rgpd a {
  padding-left: 10px;
}

.card-signup-selector {
  margin-top: -32px;
  min-height: 360px;
}
</style>
