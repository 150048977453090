<template>
  <login-card
    :name="appDisplayName"
    :logo="appLogoPrimary"
    :btn-label="$t('common.enviar_enlace')"
    :link-label="$t('common.cancel')"
    :loading="loading"
    @click-btn="submitData(email)"
    @click-link="$emit('click-link')"
  >
    <h1 class="headline mb-5">{{ $t('message.forgot_password') }}</h1>
    <p class="grey--text">
      {{ $t('message.reset_texto') }}
    </p>
    <v-text-field
      v-model="email"
      dense
      filled
      autofocus
      outlined
      type="email"
      name="login"
      :label="email ? $t('message.email') : null"
      :placeholder="$t('message.intro_email')"
    />
  </login-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoginCard from '@/components/Login/LoginCard.vue';
import { resetPasswordUrl } from '../../config/config';

export default {
  components: {
    LoginCard,
  },

  data() {
    return {
      loading: false,
      email: '',
    };
  },

  computed: {
    ...mapState({
      appName: state => state.app.name,
      appDisplayName: state => state.app.displayName,
      appLogoPrimary: state => state.app.images.logoPrimary,
    }),
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    async submitData(email) {
      if (!this.validateData(email)) {
        return;
      }

      this.loading = true;

      try {
        const recaptchaToken = await this.$recaptcha('login');

        const res = await this.$http.post(resetPasswordUrl, {
          accion: 'enviarEnlace',
          username: email,
          recaptchaToken: recaptchaToken,
        });

        if (res.data === '1') {
          this.$toast.success(this.$t('success.send_password'));
          this.mode = 'login';
          this.$emit('click-link');
        } else if (res.data === '2') {
          this.$toast.error(this.$t('errors.usr_dado_baja'));
        } else {
          this.$toast.error(this.$t('errors.email_no_exist', { name: this.appDisplayName }));
        }
      } catch (err) {
        this.$log.error(err);
      } finally {
        this.loading = false;
      }
    },

    validateData(email) {
      //TODO: use @utils/validator.js
      const exprMail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (!email) {
        this.$toast.error(this.$t('errors.email_required'));
        return false;
      }

      if (!exprMail.test(email)) {
        this.$toast.error(this.$t('errors.email_invalid'));
        return false;
      }

      return true;
    },
  },
};
</script>
