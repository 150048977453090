<template>
  <v-layout>
    <v-flex>
      <transition name="slide-fade">
        <v-card v-if="professionals.length > 0" class="mb-5 card-signup" min-height="480px">
          <v-card-text>
            <h2 class="headline pt-5 mb-2">{{ $t('signup.title_patient') }}</h2>
            <h3 class="mb-8 font-weight-light">{{ $t('signup.subtitle_patient', { name: displayName }) }}</h3>
            <v-form ref="form" v-model="validForm" lazy-validation>
              <v-layout wrap>
                <v-flex xs12 sm6 class="px-1">
                  <v-text-field
                    v-model="form.name"
                    outlined
                    :label="$t('patient.first_name') + ' *'"
                    :rules="rules.nameOrSurname"
                  />
                </v-flex>
                <v-flex xs12 sm6 class="px-1">
                  <v-text-field
                    v-model="form.surname"
                    outlined
                    :label="$t('patient.last_name') + ' *'"
                    :rules="rules.nameOrSurname"
                  />
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-identify-document
                    :dense="false"
                    :filled="false"
                    :id-document="form.idDocument"
                    :id-document-type="form.idDocumentType"
                    use-required
                    @onError="handleIdErrors"
                    @input="setIdDocument"
                  />
                </v-flex>

                <v-flex xs12 sm6 class="px-1">
                  <v-menu
                    ref="menuDatePicker"
                    v-model="menuDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatted"
                        outlined
                        :label="$t('patient.birthdate') + ' *'"
                        append-icon="mdi-calendar"
                        readonly
                        :rules="rules.requiredField"
                        v-bind="attrs"
                        @click:append="handleDatePicker"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      ref="datePicker"
                      v-model="form.dateOfBirth"
                      :max="maxDateOfBirth"
                      :min="minDateOfBirth"
                      color="primary"
                      @input="menuDatePicker = false"
                    />
                  </v-menu>
                </v-flex>

                <v-flex sm6 lg6 class="px-1">
                  <v-select
                    v-model="form.sex"
                    outlined
                    filled
                    class="mx-1"
                    item-text="label"
                    item-value="value"
                    :items="sexItems"
                    :label="$t('patient.gender') + ' *'"
                    :rules="rules.requiredField"
                  />
                </v-flex>

                <v-flex xs12 sm6 class="px-1">
                  <v-text-field
                    v-model="form.email"
                    :rules="rules.emailRequired"
                    outlined
                    :label="$t('message.email') + ' *'"
                  />
                </v-flex>
                <v-flex xs12 sm6 class="px-1">
                  <v-text-field
                    v-model="form.repeat_email"
                    :rules="rules.emailMatches"
                    outlined
                    :label="$t('message.repeat_email') + ' *'"
                  />
                </v-flex>

                <v-flex sm6 lg6 class="px-1">
                  <v-tel-field
                    v-model="form.phone"
                    :allow-web-phone="true"
                    outlined
                    filled
                    class="mx-1"
                    v-bind="bindProps"
                    :rules="rules.phone"
                  />
                </v-flex>

                <v-flex sm6 lg6 class="px-1">
                  <v-select
                    v-model="form.lang"
                    outlined
                    filled
                    class="mx-1"
                    :items="availableLanguages"
                    :label="$t('common.lang') + '  *'"
                    menu-props="auto"
                    required
                    item-text="title"
                    item-value="lang"
                    :rules="rules.requiredField"
                  />
                </v-flex>

                <v-flex v-if="professionals.length > 0" xs12 class="px-1">
                  <v-autocomplete
                    v-model="form.professionalId"
                    outlined
                    :label="$t('signup.select_professional') + '  *'"
                    :items="professionals"
                    :filter="filterProfessionals"
                    chips
                    item-text="name"
                    item-value="id"
                    required
                    :rules="rules.requiredField"
                  >
                    <template slot="selection" slot-scope="data">
                      <v-chip
                        :input-value="data.selected"
                        close
                        class="chip--select-multi"
                        color="blue lighten-4"
                        @click:close="removeProfessionalToInput(data.item.id)"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template slot="item" slot-scope="data">
                      <!-- eslint-disable-next-line prettier/prettier -->
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex v-if="companies.length > 0" xs12 class="px-1">
                  <v-autocomplete
                    v-model="form.companyId"
                    outlined
                    :label="$t('signup.select_center')"
                    :items="companies"
                    chips
                    item-text="name"
                    item-value="id"
                  >
                    <template slot="selection" slot-scope="data">
                      <v-chip
                        :input-value="data.selected"
                        close
                        class="chip--select-multi"
                        color="blue lighten-4"
                        @click:close="removeCompanyToInput(data.item.id)"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template slot="item" slot-scope="data">
                      <!-- eslint-disable-next-line prettier/prettier -->
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout v-if="showConditionsCheck" class="mb-5">
                <v-checkbox v-model="checkbox" class="mt-0" hide-details :rules="rules.requiredField" />
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="pt-1" v-html="conditionsLabel" />
              </v-layout>
              <v-layout v-if="showTermsAndConditions" class="mb-5 text-left">
                <v-checkbox v-model="checkboxTermCompany" class="mt-0" hide-details :rules="rules.requiredField" />
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="pt-1" v-html="termAndConditionsCompany" />
              </v-layout>
              <v-btn
                :loading="isLoading"
                rounded
                depressed
                large
                color="primary"
                :disabled="incompleteForm || !termsAndConditionsAccepted"
                class="px-5 mb-10"
                @click="validate"
              >
                {{ $t('message.start') }}
              </v-btn>
            </v-form>

            <v-btn text color="primary" class="mb-5" @click="goToLogin">
              {{ $t('message.already_have_an_account') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </transition>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import environment from '@/environment';
import Alerts from '@/mixins/Alerts';
import FormValidation from '@/mixins/FormValidation';
import VIdentifyDocument from '../../modules/VuetifyIdentityDocument/components/VIdentifyDocument.vue';
import { signUpUrl, getHeader, defaultCountry } from '@/config/config';
import { datePickerMinDate } from '@/services/dateHelper';
import { hasDiacritics, removeDiacritics } from '@/utils/diacritics';

export default {
  name: 'SignupPatientForm',

  components: {
    VIdentifyDocument,
  },

  mixins: [Alerts, FormValidation],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      displayName: environment.displayName,
      colorPrimary: this.$vuetify.theme.primary + 'eb',
      availableLanguages: [
        {
          lang: 'en',
          title: this.$t('common.english'),
        },
        {
          lang: 'es',
          title: this.$t('common.spanish'),
        },
      ],
      professionals: [],
      companies: [],
      conditionsLabel: '',
      validForm: true,
      menuDatePicker: null,
      dateFormatted: null,
      form: {
        name: null,
        surname: null,
        email: null,
        repeat_email: null,
        idDocument: '',
        idDocumentType: 1,
        sex: 0,
        phonePrefix: '',
        phone: '',
        lang: '',
        dateOfBirth: null,
        professionalId: null,
        companyId: null,
      },
      checkbox: false,
      checkboxTermCompany: false,
      termAndConditionsCompany: '',
      isLoading: false,
      sexItems: [
        {
          value: '0',
          label: this.$t('patient.female'),
        },
        {
          value: '1',
          label: this.$t('patient.male'),
        },
        {
          value: '2',
          label: this.$t('patient.other'),
        },
      ],
      bindProps: {
        mode: 'international',
        defaultCountry: defaultCountry ? defaultCountry : 'ES',
        preferredCountries: ['ES'],
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: this.$t('patient.phone') + ' *',
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      rules: {
        emailMatches: [this._required(), this._matches(this, 'form.email')],
      },
    };
  },

  computed: {
    ...mapState({
      appLoginBackground: state => state.app.images.loginBackground,
      appLocale: state => state.app.locale,
    }),

    minDateOfBirth() {
      return datePickerMinDate();
    },

    maxDateOfBirth() {
      return this.getDateAgo(18);
    },

    currentLanguageName() {
      const found = this.availableLanguages.find(item => item.lang === this.$i18n.locale);
      return found.title;
    },

    incompleteForm() {
      return this.isLoading || !this.validForm || !this.form.dateOfBirth || !this.form.idDocument;
    },

    showConditionsCheck() {
      return this.conditionsLabel !== '';
    },

    showTermsAndConditions() {
      return this.termAndConditionsCompany !== '';
    },

    termsAndConditionsAccepted() {
      return (
        (!this.showConditionsCheck || this.checkbox === true) &&
        (!this.showTermsAndConditions || this.checkboxTermCompany === true)
      );
    },
  },

  watch: {
    menuDatePicker(val) {
      val && this.handleDatePicker();
    },

    'form.dateOfBirth'() {
      this.dateFormatted = this.formatDate(this.form.dateOfBirth);
    },

    appLocale(value) {
      this.setLocaleApp(value);
    },
  },

  mounted() {
    this.setLocaleApp(this.appLocale);
    this.validForm = false;
    this.$recaptchaInstance && this.$recaptchaInstance.showBadge();
    (environment?.signUpAs?.selectorByCompanies && this.loadCompanies()) || this.loadProfessionals();

    this.handleRgpd();
  },

  beforeDestroy() {
    this.$recaptchaInstance && this.$recaptchaInstance.hideBadge();
  },

  methods: {
    filterProfessionals(item, queryText, itemText) {
      if (hasDiacritics(queryText)) {
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
      }
      return removeDiacritics(itemText.toLocaleLowerCase()).indexOf(queryText.toLocaleLowerCase()) > -1;
    },

    handleDatePicker() {
      setTimeout(() => {
        this.$refs.datePicker && (this.$refs.datePicker.activePicker = 'YEAR');
        this.menuDatePicker = true;
      });
    },

    handleRgpd() {
      this.rgpd = environment?.footer?.rgpd;
      if (!this.rgpd.showOnActivation) return;

      this.conditionsLabel = this.$t('message.acceptConditions', {
        privacyPolicyLink: this.rgpd.urlPolicy,
        legalLink: this.rgpd.urlLegal,
      });
    },

    loadProfessionals() {
      this.isLoading = true;
      const professionalId = this.checkSlugProfessionalId();
      this.$http
        .get(signUpUrl + '/professionals?professionalId=' + professionalId, { headers: getHeader() })
        .then(res => {
          this.professionals = res.body.data;
          if (res.body.data.length > 0) {
            this.form.professionalId = res.body.data[0].id;
          }
          this.$http
            .get(signUpUrl + '/companyOfProffesional/' + professionalId, { headers: getHeader() })
            .then(res => {
              if (
                res.status === 200 &&
                res.data.length !== 0 &&
                res.data.terms_conditions_signup !== '' &&
                res.data.terms_conditions_signup !== null
              ) {
                this.termAndConditionsCompany = res.data.terms_conditions_signup;
              }
            })
            .catch(err => {
              this.catchError(err);
            });
        })
        .catch(err => {
          this.catchError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    loadCompanies() {
      this.isLoading = true;
      const professionalId = this.checkSlugProfessionalId();
      this.$http
        .get(signUpUrl + '/companies?professionalId=' + professionalId, { headers: getHeader() })
        .then(res => {
          this.companies = res.body.data;
          if (res.body.data.length > 0) {
            this.form.companyId = res.body.data[0].id;
          }
        })
        .catch(err => {
          this.catchError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    checkSlugProfessionalId() {
      let professionalId = 0;
      if (window.localStorage.getItem('pathRequiresAuth')) {
        const path = JSON.parse(window.localStorage.getItem('pathRequiresAuth'))['fullPath'];
        if (path.includes('hiring')) {
          const values = path.split('/');
          professionalId = values.length > 2 ? values[2] : 0;
        }
      }
      return professionalId;
    },

    removeProfessionalToInput() {
      this.form.professionalId = null;
    },

    removeCompanyToInput() {
      this.form.companyId = null;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    getDateAgo(years) {
      let d = new Date();
      d = d.setFullYear(d.getFullYear() - years);
      return new Date(d).toISOString().substr(0, 10);
    },

    goToLogin() {
      this.$router.push('/login');
    },

    handleIdErrors() {
      this.$nextTick(() => {
        this.validForm = false;
      });
    },

    setIdDocument(data) {
      this.validForm = true;
      this.form.idDocument = data.idDocument;
      this.form.idDocumentType = data.idDocumentType;
    },

    validate() {
      this.validForm = this.$refs.form.validate();

      if (!this.validForm) {
        this.$toast.error(this.$t('errors.form_invalid'));
        return;
      }

      this.sendForm();
    },

    async sendForm() {
      try {
        this.isLoading = true;

        const recaptchaToken = await this.$recaptcha('login');
        this.managePhone();

        await this.$http.post(signUpUrl + '/patient', { ...this.form, recaptchaToken }, { headers: getHeader() });

        this.form = {
          name: null,
          surname: null,
          email: null,
          repeat_email: null,
          sex: 0,
          phonePrefix: '',
          phone: '',
          lang: '',
          idDocument: '',
          idDocumentType: 1,
          dateOfBirth: null,
        };

        this.$emit('success', true);
      } catch (err) {
        this.catchError(err?.body?.error);
      } finally {
        this.isLoading = false;
      }
    },

    managePhone() {
      if (this.form.phone.length > 11) {
        const phone_prefix = this.form.phone.substring(0, this.form.phone.indexOf(' '));
        let phone = this.form.phone.substring(this.form.phone.indexOf(' '));
        phone = phone.replace(/ +/g, '').replace(/-/g, '');
        this.form.phone = phone;
        this.form.phonePrefix = phone_prefix;
      }
    },

    setLocaleApp(value) {
      this.form.lang = value;
    },

    catchError(err) {
      this.$log.error(err);

      let msg = '';

      if (err?.data?.error) {
        msg = this.$t(err.data.error);
      } else if (this.$te(err)) {
        this.$toast.error(this.$t(err));
      } else {
        msg = err?.response?.data?.detail || this.$t('errors.try_again');
      }

      this.$toast.error(msg);
    },
  },
};
</script>

<style lang="scss">
.slide-fade {
  &-enter-active {
    transition: all 0.3s ease;
  }
  &-leave-active {
    transition: all 0.4s ease;
  }
  &-enter, &-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
}

#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
}

.rgpd a {
  padding-left: 10px;
}

.card-signup {
  margin-top: -32px;
}
</style>
