<template>
  <figure v-if="qr" class="text-center">
    <img :src="qr" alt="Qr code" />
  </figure>
</template>

<script>
import QRCode from 'qrcode';

export default {
  name: 'QrCode',

  props: {
    source: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      qr: null,
    };
  },

  mounted() {
    this.generateQr();
  },

  methods: {
    async generateQr() {
      try {
        this.qr = await QRCode.toDataURL(this.source);
        this.$emit('generate', { qr: this.qr });
      } catch (e) {
        console.info(e);
      }
    },
  },
};
</script>
