<template>
  <div>
    <v-card-text class="px-0 py-0">
      <account-two-fa-intro v-if="showIntro" @accept="handleIntro" />
      <account-two-fa-stepper v-if="showStepper" @success="handleSuccess" @error="handleError" />
      <account-two-fa-confirmation v-if="showConfirmation" :secret="secret" :qr="qr" />
    </v-card-text>
  </div>
</template>

<script>
import AccountTwoFaIntro from '@/components/account/ui/AccountTwoFaIntro';
import AccountTwoFaStepper from '@/components/account/ui/AccountTwoFaStepper';
import AccountTwoFaConfirmation from '@/components/account/ui/AccountTwoFaConfirmation';

export default {
  name: 'AccountTwoFaSetup',

  components: {
    AccountTwoFaIntro,
    AccountTwoFaStepper,
    AccountTwoFaConfirmation,
  },

  data() {
    return {
      showIntro: true,
      showStepper: false,
      showConfirmation: false,
      secret: '',
      qr: '',
    };
  },

  methods: {
    handleIntro() {
      this.showIntro = false;
      this.showStepper = true;
    },

    handleSuccess({ secret, qr }) {
      this.showStepper = false;
      this.showConfirmation = true;
      this.secret = secret;
      this.qr = qr;
    },

    handleError() {
      this.$toast.error(this.$t('2fa.errorOnSetup'));
    },
  },
};
</script>
