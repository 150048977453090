<template>
  <v-main>
    <v-container v-if="isLoggedIn" fluid fill-height>
      <MaintenanceMode v-if="maintenanceMode" />
      <MigrationExplanation v-else-if="showMigrationExplanation" @goToLogin="showMigrationExplanation = false" />
      <IncompatibleBrowser
        v-else-if="showIncompatibleBrowserMessage"
        :show-icon="incompatibleBrowserShowIcon"
        :title="incompatibleBrowserTitle"
        :message="incompatibleBrowserMessage"
        :message2="incompatibleBrowserMessage2"
        :show-help-center="incompatibleBrowserShowHelpCenter"
        :help-center-link="incompatibleBrowserHelpCenterLink"
        :help-center-message="incompatibleBrowserHelpCenterMessage"
        :help-center-link-name="incompatibleBrowserHelpCenterLinkName"
      />
      <v-layout v-else align-center justify-center class="mb-5">
        <v-sheet :max-width="$vuetify.breakpoint.smAndDown ? '320px' : '900px'" width="90%" class="transparent mb-5">
          <v-layout align-center justify-center>
            <v-flex v-if="$vuetify.breakpoint.mdAndUp" class="pa-5 text-center white--text bg-gradient py-5">
              <h2 class="headline pt-5 mb-4">{{ $t('message.welcome', { name: displayName }) }}</h2>
              <span v-if="canSignup">
                <p class="mb-5">{{ $t('message.new_here') }}</p>
                <v-btn color="white" class="mb-5" outlined rounded @click="goToSignup">{{
                  $t('message.signup')
                }}</v-btn>
              </span>
            </v-flex>
            <v-sheet class="transparent" max-width="320px">
              <!-- login form -->
              <login-form-auth
                v-if="!isKeycloakIntegration"
                v-show="mode === 'login'"
                :redirect="redirect"
                :fa-code="faCode"
                :access-token="accessToken"
                :refresh-token="refreshToken"
                @click-link="mode = 'reset'"
                @two-fa-login="handleTwoFaLogin"
                @on-account-selector-visible="handleAccountSelector"
                @username-changed="usernameChanged"
              />


              <LoginOidc v-if="isKeycloakIntegration" />

              <!-- password form -->
              <login-form-reset-password v-if="mode === 'reset'" @click-link="mode = 'login'" />
              <!-- 2fa form -->
              <login-form-two-fa
                v-if="mode === '2fa'"
                :invalid-code-error="twoFaErrors"
                :loading="twoFaLoading"
                :username="username"
                @handle-two-fa="setTwoFaCode"
                @reset-error="handleTwoFaError"
                @click-link="mode = 'login'"
              />
              <v-layout class="mb-3 body-3">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <a
                      v-if="rgpd.urlPolicy && !hideLanguageSelector"
                      v-tooltip="$t('message.change_language')"
                      target="_blank"
                      class="grey--text mx-2 text-decoration-none"
                      v-on="on"
                      >{{ currentLanguageName }} <v-icon size="16">mdi-chevron-down</v-icon></a
                    >
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="(item, index) in availableLanguages"
                      :key="index"
                      @click="$i18n.locale = item.lang"
                    >
                      <v-list-item-title class="font-weight-regular">
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-spacer />
                <a
                  v-if="rgpd.urlPolicy"
                  :href="rgpd.urlPolicy"
                  target="_blank"
                  class="grey--text mx-2 text-decoration-none"
                  >{{ $t('common.privacy') }}</a
                >
                <a
                  v-if="rgpd.urlCookies"
                  :href="rgpd.urlCookies"
                  target="_blank"
                  class="grey--text mx-2 text-decoration-none"
                  >{{ $t('common.cookies') }}</a
                >
                <a
                  v-if="rgpd.urlConditions"
                  :href="rgpd.urlConditions"
                  target="_blank"
                  class="grey--text mx-2 text-decoration-none"
                  >{{ $t('common.aviso_legal') }}</a
                >
              </v-layout>
            </v-sheet>
          </v-layout>
        </v-sheet>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { mapState } from 'vuex';
import environment from '@/environment';
import LoginFormAuth from '@/components/Login/LoginFormAuth.vue';
import MaintenanceMode from '@/components/Login/MaintenanceMode';
import MigrationExplanation from '@/components/Login/MigrationExplanation';
import IncompatibleBrowser from '@/components/Login/IncompatibleBrowser';
import LoginFormTwoFa from '@/components/Login/LoginFormTwoFa';
import LoginOidc from "@/components/Login/LoginOidc.vue";

export default {
  components: {
    LoginOidc,
    LoginFormTwoFa,
    MigrationExplanation,
    IncompatibleBrowser,
    MaintenanceMode,
    LoginFormAuth,
    LoginFormResetPassword: () =>
      import(/* webpackChunkName: "login" */ '@/components/Login/LoginFormResetPassword.vue'),
  },
  props: {
    accessToken: {
      type: String,
      default: null,
    },
    refreshToken: {
      type: String,
      default: null,
    },
    redirect: {
      type: String,
      default: function() {
        return null;
      },
    },
  },

  data() {
    return {
      mode: 'login',
      itereaciones_itercom: 0,
      environment: environment,
      canSignup: environment.signUpAs.patient || environment.signUpAs.professional,
      hideLanguageSelector: environment?.forms?.hideLanguageSelector ? environment?.forms?.hideLanguageSelector : false,
      rgpd: environment.footer.rgpd,
      displayName: environment.displayName,
      colorPrimary: this.$vuetify.theme.primary + 'eb',
      maintenanceMode: environment.maintenanceMode,
      showIncompatibleBrowserMessage: false,
      incompatibleBrowserShowIcon: true,
      incompatibleBrowserTitle: '',
      incompatibleBrowserMessage: '',
      incompatibleBrowserMessage2: '',
      incompatibleBrowserShowHelpCenter: true,
      incompatibleBrowserHelpCenterLink: '',
      incompatibleBrowserHelpCenterMessage: '',
      incompatibleBrowserHelpCenterLinkName: '',
      showMigrationExplanation: this.$route.query.migrated === '1',
      availableLanguages: [
        {
          lang: 'en',
          title: 'English',
        },
        {
          lang: 'es',
          title: 'Español',
        },
      ],
      showSignup: false,
      showTwoFaLogin: false,
      faCode: '',
      twoFaErrors: false,
      twoFaLoading: false,
      username: null,
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      appLoginBackground: state => state.app.images.loginBackground,
    }),
    currentLanguageName() {
      const locale = this.$i18n?.locale || 'es';
      const found = this.availableLanguages.find(item => item.lang === locale);
      return found && found.title;
    },
    isKeycloakIntegration() {
      return environment.isKeycloakIntegration;
    },
    isLoggedIn() {
      return this.isKeycloakIntegration ? this.$keycloak.authenticated : true;
    },
  },
  mounted() {
    this.handlerMigrationExplanation();
    if (this.$recaptchaInstance) {
      this.$recaptchaInstance.showBadge();
    }
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      this.showIncompatibleBrowserMessage = true;
    }
  },

  beforeDestroy() {
    if (this.$recaptchaInstance) {
      this.$recaptchaInstance.hideBadge();
    }
  },

  methods: {
    login() {
      this.$keycloak.login();
    },
    handlerMigrationExplanation() {
      setTimeout(
        function() {
          this.showMigrationExplanation = false;
        }.bind(this),
        10000
      );
    },
    goToSignup() {
      this.$router.push('/signup');
    },

    setTwoFaCode(code) {
      this.twoFaLoading = true;
      this.faCode = code;
    },

    handleTwoFaLogin({ hasError = false } = {}) {
      this.mode = '2fa';

      if (hasError) {
        this.faCode = '';
        this.twoFaErrors = true;
        this.twoFaLoading = false;
      }
    },

    handleTwoFaError() {
      this.twoFaErrors = false;
      this.twoFaLoading = false;
    },

    handleAccountSelector() {
      this.twoFaLoading = false;
      this.mode = 'login';
    },
    usernameChanged(val) {
      this.username = val;
    },
  },
};
</script>

<style scoped>
.bg-gradient {
  background: var(--v-secondary-base);
  background: linear-gradient(114deg, var(--v-primary-base) 20%, var(--v-secondary-base) 100%);
  padding: 80px 340px 120px 12px !important;
  margin-right: -340px;
  border-radius: 10px;
  margin-top: -50px;
}
</style>
