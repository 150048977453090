<template>
  <div class="two-fa-stepper">
    <v-stepper class="elevation-0" :value="currentStep">
      <!-- stepper header -->
      <v-stepper-header v-if="!isAndroidOrIos" class="elevation-0">
        <v-stepper-step step="1" :complete="isComplete(1)" :color="getColor(isComplete(1))">
          {{ $t('2fa.downloadApp') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="2" :complete="isComplete(2)" :color="getColor(isComplete(2))">
          {{ $t('2fa.scanQr') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="3" :complete="isComplete(3)" :color="getColor(isComplete(3))">
          {{ $t('2fa.enterCode') }}
        </v-stepper-step>
      </v-stepper-header>
      <!-- stepper content -->
      <v-stepper-items>
        <v-stepper-content id="step-download" step="1" class="pt-0 pb-0">
          <account-two-fa-step-download @accept="currentStep++" />
        </v-stepper-content>
        <v-stepper-content id="step-generate" step="2" class="pt-0 pb-0">
          <account-two-fa-step-generator @accept="handleGeneratorAccept" @cancel="currentStep--" />
        </v-stepper-content>
        <v-stepper-content id="step-checker" step="3" class="pt-0 pb-0">
          <account-two-fa-step-checker
            :set-autofocus="setAutofocus"
            :secret="secret"
            @accept="handleCheckerAccept"
            @cancel="currentStep--"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AccountTwoFaStepDownload from '@/components/account/ui/AccountTwoFaStepDownload';
import AccountTwoFaStepGenerator from '@/components/account/ui/AccountTwoFaStepGenerator';
import AccountTwoFaStepChecker from '@/components/account/ui/AccountTwoFaStepChecker';

export default {
  name: 'AccountTwoFaStepper',

  components: {
    AccountTwoFaStepDownload,
    AccountTwoFaStepGenerator,
    AccountTwoFaStepChecker,
  },

  data() {
    return {
      currentStep: 1,
      secret: null,
      qr: null,
      setAutofocus: false,
    };
  },

  computed: {
    ...mapGetters({
      isAndroidOrIos: 'app/isAndroidOrIos',
    }),
  },

  methods: {
    ...mapActions({
      enableTwoFa: 'account/enableTwoFa',
    }),

    isComplete(step) {
      return step < this.currentStep;
    },

    getColor(value) {
      return value ? 'green accent-4' : 'primary';
    },

    handleGeneratorAccept({ secret, qr }) {
      this.secret = secret;
      this.qr = qr;
      this.currentStep++;
    },

    async handleCheckerAccept() {
      const twoFaEnabled = await this.enableTwoFa(this.secret);

      this.$emit(twoFaEnabled ? 'success' : 'error', { secret: this.secret, qr: this.qr });
    },
  },
};
</script>

<style lang="scss" scoped>
.two-fa-stepper {
  ::v-deep .v-stepper__content {
    @include breakpoint(only-phone) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
