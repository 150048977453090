<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="two-fa-checker" @keyup.enter="handleEnter">
    <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0">
      {{ $t('2fa.checkCodeTitle') }}
    </v-card-title>
    <p class="text-body-1 text--secondary" v-html="$t('2fa.qrText')" />
    <div class="two-fa-checker__input" :class="{ 'two-fa-checker__input--has-errors': invalidCodeError }">
      <pincode-input ref="pincode" v-model="code" :length="codeLength" />
      <span v-if="invalidCodeError" class="two-fa-checker__error mt-3">{{ invalidCodeText }}</span>
    </div>
    <!-- actions -->
    <v-card-actions class="mt-16">
      <action-buttons
        :accept-text="$t('2fa.setup')"
        :cancel-text="$t('common.volver_atras')"
        :disable-accept-button="disableAcceptButton"
        @on-click:accept="handleAccept"
        @on-click:cancel="handleCancel"
      />
    </v-card-actions>
  </div>
</template>

<script>
import { authenticator } from 'otplib';
import PincodeInput from 'vue-pincode-input';
import ActionButtons from '@/components/ui/ActionButtons';
import { TWO_FA_CODE_LENGTH } from '@/config/constants';

export default {
  name: 'AccountTwoFaStepChecker',

  components: {
    ActionButtons,
    PincodeInput,
  },

  props: {
    secret: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      code: '',
      codeLength: TWO_FA_CODE_LENGTH,
      invalidCodeError: false,
      invalidCodeText: this.$t('2fa.invalidCodeError'),
      disableAcceptButton: false,
      domObserverId: 'step-checker',
      pincodeInputEl: '.vue-pincode-input',
    };
  },

  mounted() {
    this.observeChangesInVisibility();
  },

  methods: {
    handleEnter() {
      this.handleAccept();
    },

    handleAccept() {
      this.verifyCode() && this.$emit('accept');
    },

    handleCancel() {
      this.$emit('cancel');
    },

    verifyCode() {
      const validCode = authenticator.check(this.code, this.secret);

      if (!validCode) {
        this.invalidCodeError = true;
        return false;
      }

      this.disableAcceptButton = false;
      return true;
    },

    observeChangesInVisibility() {
      const observer = new MutationObserver(mutations => {
        mutations.forEach((_, index) => {
          if (!index) this.manualAutofocus();
        });
      });
      observer.observe(document.getElementById(this.domObserverId), { attributes: true });
    },

    manualAutofocus() {
      const pincode = this.$refs?.pincode?.$el;
      if (!pincode) return;

      const input = pincode.querySelector(this.pincodeInputEl);
      setTimeout(_ => input.focus(), 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.two-fa-checker {
  &__input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ::v-deep input {
      @extend %two-fa-input;
    }

    &--has-errors {
      ::v-deep input {
        border-color: #ff5454;
      }
    }
  }

  &__error {
    color: #ff5454;
  }
}
</style>
