<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="two-fa-generator">
    <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0">
      {{ $t(isAndroidOrIos ? '2fa.copyPasteKey' : '2fa.scanQr') }}
    </v-card-title>
    <!-- qr -->
    <p v-if="isAndroidOrIos" class="text-body-1 text--secondary" v-html="$t('2fa.qrTextMobile')" />
    <p v-else class="text-body-1 text--secondary" v-html="$t('2fa.qrText')" />
    <qr-code v-show="!isAndroidOrIos" :source="otpauth" @generate="handleQr" />
    <!-- secret input -->
    <div class="two-fa-generator__secret-group text-center mt-4">
      <p v-if="!isAndroidOrIos" class="text-body-2 text--secondary" v-html="$t('2fa.qrHint')" />
      <div class="d-flex justify-md-center">
        <span id="two-fa-secret" class="two-fa-generator__secret text--secondary">{{ secret }}</span>
        <copy-button
          check-color="white"
          squared
          primary
          use-toast
          discreet-toast
          element-to-copy="#two-fa-secret"
          class="two-fa-generator__copy"
        />
      </div>
    </div>
    <!-- actions -->
    <v-card-actions class="mt-16">
      <v-row>
        <v-col class="px-1 py-1 text-right">
          <v-btn color="primary" outlined class="mr-4" @click="handleCancel">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn color="primary" data-test="2fa_already_scanned" depressed @click="handleAccept">
            {{ isAndroidOrIos ? $t('2fa.doneIt') : $t('2fa.alreadyScanned') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>

<script>
import { authenticator } from 'otplib';
import { mapState, mapGetters } from 'vuex';
import QrCode from '@/components/common/QrCode';
import CopyButton from '@/components/common/CopyButton';

export default {
  name: 'AccountTwoFaStepGenerator',

  components: {
    QrCode,
    CopyButton,
  },

  data() {
    return {
      secret: authenticator.generateSecret(),
      qr: null,
    };
  },

  computed: {
    ...mapState({
      userEmail: ({ account }) => account?.authUser?.email,
      service: ({ app }) => app?.displayName,
    }),

    ...mapGetters({
      isAndroidOrIos: 'app/isAndroidOrIos',
    }),

    code() {
      return authenticator.generate(this.secret);
    },

    otpauth() {
      return authenticator.keyuri(this.userEmail, this.service, this.secret);
    },
  },

  methods: {
    handleAccept() {
      this.$emit('accept', { secret: this.secret, qr: this.qr });
    },

    handleCancel() {
      this.$emit('cancel');
    },

    handleQr({ qr }) {
      this.qr = qr;
    },
  },
};
</script>

<style lang="scss" scoped>
.two-fa-generator {
  &__secret-group {
    width: 100%;
    max-width: 100%;
    @include breakpoint(ml) {
      width: rem(360);
      margin: 0 auto;
    }
  }

  &__secret {
    border: 1px solid #e9e9e9;
    border-right: none;
    padding: rem(8) rem(16);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &__copy {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
  }
}
</style>
