import i18n from '../../engine/framework/modules/i18n';

export const getGender = id => {
  const options = {
    0: i18n.t('patient.female'),
    1: i18n.t('patient.male'),
    2: i18n.t('patient.other'),
  };
  return options[id];
};
