<template>
  <div>
    <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0">
      {{ $t('change_password.change_password') }}
    </v-card-title>
    <v-card-text class="px-0 py-0">
      <change-password-form
        :user-id="userId"
        show-cancel-button
        :cancel-button-text="$t('account.cancel')"
        @on-success="handleSuccess"
        @on-error="handleError"
        @on-cancel="handleCancel"
      />
    </v-card-text>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ChangePasswordForm from '@/components/shared/ChangePasswordForm';

export default {
  name: 'AccountPassword',

  components: {
    ChangePasswordForm,
  },

  computed: {
    ...mapState('account', {
      userId: 'userId',
    }),
  },

  methods: {
    ...mapActions({
      showAlert: 'layout/showAlert',
    }),

    handleSuccess(message) {
      this.showAlert({ color: 'success', message: message });
      this.$router.push({ name: 'AccountMainView' });
    },

    handleError(message) {
      this.showAlert({ color: 'error', message: message });
    },

    handleCancel() {
      this.$router.push({ name: 'AccountMainView' });
    },
  },
};
</script>
