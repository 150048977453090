<template>
  <div class="two-fa-confirmation">
    <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0 d-flex flex-column">
      <span class="success px-3 py-3 rounded-circle">
        <v-icon x-large color="white">mdi-check</v-icon>
      </span>
      <span class="mt-6">{{ $t('2fa.setupFinishedTitle') }}</span>
    </v-card-title>
    <v-card-text class="text-body-1 text--secondary text-center">
      {{ $t('2fa.setupFinishedTitle') }}
    </v-card-text>
    <v-card-actions class="mt-4 d-flex justify-center flex-column">
      <v-btn depressed color="primary" class="px-4" @click="handleCodeDownload">
        <v-icon class="mr-2">mdi-download</v-icon>
        {{ $t('2fa.downloadCodeButton') }}
      </v-btn>
      <p class="two-fa-confirmation__hint text-body-2 text--secondary">
        <v-icon class="two-fa-confirmation__hint-icon" color="primary">mdi-exclamation-thick</v-icon>
        <span>{{ $t('2fa.downloadCodeHint') }}</span>
      </p>
      <v-btn :to="{ name: accountNamePaths.accountMain }" text color="primary" class="mt-6" @click="handleCodeDownload">
        {{ $t('2fa.backToMyAccount') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { TWO_FA_CODES_FILENAME } from '@/config/constants';
import { accountNamePaths } from '@/router/routes/account';

export default {
  name: 'AccountTwoFaConfirmation',

  props: {
    secret: {
      type: String,
      required: true,
    },
    qr: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      accountNamePaths,
      doc: null,
      stream: null,
      bloc: null,
    };
  },

  methods: {
    handleCodeDownload() {
      this.generatePdf();
      this.stream.on('finish', () => this.downloadPdf());
    },

    generatePdf() {
      const PDFDocument = require('pdfkit/js/pdfkit.standalone');
      const blobStream = require('blob-stream');

      this.doc = new PDFDocument();
      this.stream = this.doc.pipe(blobStream());

      this.doc
        .fillColor('#10587E')
        .fontSize(24)
        .text('Docline 2FA Security Codes', { underline: true })
        .fillColor('#262626')
        .moveDown(1)
        .fontSize(14)
        .text('Secret code:', { underline: true })
        .moveDown(0.25)
        .fontSize(16)
        .fillColor('green')
        .text(this.secret)
        .fillColor('#262626')
        .moveDown(1)
        .fontSize(14)
        .text('QR Code', { underline: true })
        .moveDown(0.25)
        .image(this.qr)
        .end();
    },

    downloadPdf() {
      this.blob = this.stream.toBlob('application/pdf');
      saveAs(this.blob, TWO_FA_CODES_FILENAME);
    },
  },
};
</script>

<style lang="scss" scoped>
.two-fa-confirmation {
  &__hint {
    width: rem(440);
    max-width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: rem(32);
  }
  &__hint-icon {
    font-size: 20px;
    border: 2px solid var(--v-primary-base);
    border-radius: 50%;
    padding: 2px;
    margin: 4px 12px 0 0;
  }
}
</style>
