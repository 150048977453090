<template>
  <v-layout>
    <v-flex>
      <signup-professional-request-send v-if="showCompletedSignup" />
      <v-card v-else class="mb-5 card-signup" min-height="480px">
        <v-card-text>
          <h2 class="headline py-5">{{ $t('signup.title_professional', { name: displayName }) }}</h2>
          <v-form ref="form" v-model="isFormValid" lazy-validation class="pt-5">
            <v-layout wrap>
              <v-flex xs12 sm6 class="px-1">
                <v-text-field
                  v-model="form.name"
                  outlined
                  filled
                  :label="$t('patient.first_name') + ' *'"
                  :rules="[rules.required]"
                />
              </v-flex>
              <v-flex xs12 sm6 class="px-1">
                <v-text-field
                  v-model="form.surname"
                  outlined
                  filled
                  :label="$t('patient.last_name') + ' *'"
                  :rules="[rules.required]"
                />
              </v-flex>
              <v-flex xs12 sm6 class="px-1">
                <v-text-field
                  v-model="form.email"
                  :rules="[rules.required, rules.email]"
                  outlined
                  filled
                  :label="$t('message.email') + ' *'"
                />
              </v-flex>
              <v-flex xs12 sm6>
                <v-tel-field
                  v-model="form.phoneNumber"
                  outlined
                  :dense="false"
                  filled
                  class="px-1"
                  v-bind="bindProps"
                  :rules="[rules.required]"
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="form.typeCompany"
                  outlined
                  filled
                  class="px-1"
                  item-text="label"
                  item-value="value"
                  :items="typeCompanyItems"
                  :label="$t('signup.typeCompany.title')"
                />
              </v-flex>
              <v-flex xs12 class="px-1">
                <v-textarea v-model="form.annotation" rows="4" outlined filled :label="$t('signup.annotation')" />
              </v-flex>
            </v-layout>
            <v-layout v-if="conditionsLabel !== ''" class="mb-5">
              <v-checkbox v-model="checkbox" class="mt-0" hide-details :rules="[rules.required]" />
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="pt-1" v-html="conditionsLabel" />
            </v-layout>
            <v-btn
              :loading="isLoading"
              rounded
              depressed
              large
              color="primary"
              :disabled="isLoading"
              class="px-5"
              @click="sendForm"
            >
              {{ $t('hiring.send') }}
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="primary" small @click="goToLogin">
            {{ $t('message.already_have_an_account') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import environment from '@/environment';
import Alerts from '@/mixins/Alerts';
import { signUpUrl, getHeader, nameEnv, defaultCountry } from '@/config/config';
import SignupProfessionalRequestSend from '@/components/Signup/SignupProfessionalRequestSend';

export default {
  name: 'SignupProfessionalForm',
  components: { SignupProfessionalRequestSend },
  mixins: [Alerts],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      displayName: environment.displayName,
      colorPrimary: this.$vuetify.theme.primary + 'eb',
      availableLanguages: [
        {
          lang: 'en',
          title: this.$t('common.english'),
        },
        {
          lang: 'es',
          title: this.$t('common.spanish'),
        },
      ],
      bindProps: {
        mode: 'international',
        defaultCountry: defaultCountry ? defaultCountry : 'ES',
        preferredCountries: nameEnv === 'dingdoc' ? ['ES'] : ['FR', 'PT'],
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: 'Teléfono *',
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      conditionsLabel: '',
      isFormValid: false,
      menuDatePicker: null,
      dateFormatted: null,
      form: {
        name: null,
        surname: null,
        idDocument: null,
        dateOfBirth: null,
        companyName: null,
        phoneNumber: null,
      },
      checkbox: false,
      isLoading: false,
      rules: {
        required: value => !!value || 'Este campo es requerido.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'El correo electrónico no es válido.';
        },
      },
      typeCompanyItems: [
        {
          value: 'Aseguradora',
          label: this.$t('signup.typeCompany.insurance'),
        },
        {
          value: 'Hospital',
          label: this.$t('signup.typeCompany.hospital'),
        },
        {
          value: 'Clinica',
          label: this.$t('signup.typeCompany.clinic'),
        },
        {
          value: 'Farmaceutica',
          label: this.$t('signup.typeCompany.pharmaceutical'),
        },
        {
          value: 'Empresa',
          label: this.$t('signup.typeCompany.company'),
        },
        {
          value: 'Otro',
          label: this.$t('signup.typeCompany.other'),
        },
      ],
      showCompletedSignup: false,
    };
  },

  computed: {
    ...mapState({
      appLoginBackground: state => state.app.images.loginBackground,
    }),

    minDateOfBirth() {
      return this.getDateAgo(65);
    },

    maxDateOfBirth() {
      return this.getDateAgo(18);
    },

    currentLanguageName() {
      const found = this.availableLanguages.find(item => item.lang === this.$i18n.locale);
      return found.title;
    },
  },

  watch: {
    menuDatePicker(val) {
      val && setTimeout(() => (this.$refs.datePicker.activePicker = 'YEAR'));
    },

    'form.dateOfBirth'() {
      this.dateFormatted = this.formatDate(this.form.dateOfBirth);
    },
  },

  mounted() {
    this.handleRgpd();
  },

  methods: {
    handleRgpd() {
      this.rgpd = environment?.footer?.rgpd;
      if (!this.rgpd.showOnActivation) return;

      this.conditionsLabel = this.$t('message.acceptConditions', {
        privacyPolicyLink: this.rgpd.urlPolicy,
        legalLink: this.rgpd.urlLegal,
      });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    getDateAgo(years) {
      let d = new Date();
      d = d.setFullYear(d.getFullYear() - years);
      return new Date(d).toISOString().substr(0, 10);
    },

    async sendForm() {
      if (!this.$refs.form.validate()) {
        return this.toastError('Por favor, complete todos los campos obligatorios');
      }
      try {
        this.isLoading = true;
        await this.$http.post(signUpUrl + '/professional', this.form, { headers: getHeader() });
        this.showCompletedSignup = true;
      } catch (err) {
        this.$toast.error(this.$t('errors.try_again'));
      } finally {
        this.isLoading = false;
      }
    },

    goToLogin() {
      this.$router.push('/login');
    },

    showError(msg) {
      this.toastError(msg);
    },

    verifyEmail() {
      if (this.form.email !== this.form.repeat_email) {
        this.form.repeat_email = '';
        this.showError(this.$t('errors.email_repeat'));
      }
    },
  },
};
</script>

<style scoped>
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
}
.rgpd a {
  padding-left: 10px;
}

.card-signup {
  margin-top: -32px;
}
</style>
