<template>
  <section>
    <v-card elevation="2" class="account-page__card mt-12">
      <account-password />
    </v-card>
  </section>
</template>

<script>
import AccountPassword from '@/components/account/layout/AccountPassword';

export default {
  name: 'AccountPasswordView',

  components: {
    AccountPassword,
  },
};
</script>
