<template>
  <div v-if="accountBodyData" class="account-body" :class="{ 'account-body--edit-mode': editMode }">
    <account-biography
      v-if="(bioEn || bioEs || editMode) && isProfessionalRole"
      :bio-en="bioEn"
      :bio-es="bioEs"
      :edit-mode="editMode"
      :locale="locale"
      @handle-textarea-es="handleTextareaEs"
      @handle-textarea-en="handleTextareaEn"
    />

    <ul class="account-body__ul">
      <!-- phone -->
      <li v-if="phone || editMode" class="account-body__li" :class="{ 'account-body__li--edit-mode': editMode }">
        <span class="account-body__icon"><v-icon :size="iconSize">mdi-phone</v-icon></span>
        <div class="account-body__li-content">
          <h3 class="account-body__title">{{ $t('common.phone_number') }}</h3>
          <v-tel-field
            v-if="editMode"
            v-model="phone"
            outlined
            dense
            filled
            background-color="white"
            :rules="rules.phone"
            v-bind="phoneFieldProps"
            @input="handlePhone"
          />
          <p v-else class="account-body__text">
            <span v-if="prefix">({{ prefix }}) </span>
            <span v-if="phone">{{ phone | maskPhone }}</span>
          </p>
        </div>
      </li>
      <!-- lang -->
      <li v-if="locale" class="account-body__li" :class="{ 'account-body__li--edit-mode': editMode }">
        <span class="account-body__icon"><v-icon :size="iconSize">mdi-web</v-icon></span>
        <div class="account-body__li-content">
          <h3 class="account-body__title">{{ $t('common.lang') }}</h3>
          <language-switcher v-if="editMode" :locale="locale" :langs="langs" @handle-locale="handleLocale" />
          <p v-else class="account-body__text">{{ displayLang }}</p>
        </div>
      </li>
      <!-- gender -->
      <li class="account-body__li" :class="{ 'account-body__li--edit-mode': editMode }">
        <span class="account-body__icon"><v-icon :size="iconSize">mdi-gender-male-female</v-icon></span>
        <div class="account-body__li-content">
          <h3 class="account-body__title">{{ $t('patient.gender') }}</h3>
          <v-gender-select
            v-if="editMode"
            :show-label="false"
            :filled="false"
            dense
            :rounded="false"
            :gender="gender"
            @input="handleGender"
          />
          <p v-else class="account-body__text">{{ getGender(gender) }}</p>
        </div>
      </li>
    </ul>
    <!-- actions -->
    <div class="account-body__actions">
      <v-btn v-if="editMode" outlined color="primary" class="mr-4" @click="handleCancel">
        {{ $t('account.cancel') }}
      </v-btn>
      <v-btn color="primary" :disabled="!isValidData" depressed @click="handleSave">
        {{ getButtonLabel }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import { defaultProps } from '@/modules/VuetifyTelField/utils';
import FormValidator from '@/mixins/FormValidation';
import LanguageSwitcher from '@/components/common/LanguageSwitcher';
import AccountBiography from '@/components/account/ui/AccountBiography';
import VGenderSelect from '@/modules/VuetifyGender/components/VGenderSelect';
import { getGender } from '@/utils/get-gender';
import environment from '@/environment';

export default {
  name: 'AccountBody',

  components: {
    LanguageSwitcher,
    AccountBiography,
    VGenderSelect,
  },

  mixins: [FormValidator],

  props: {
    headerData: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      phone: '',
      prefix: '',
      locale: '',
      bioEn: '',
      bioEs: '',
      gender: '',
      phoneFieldProps: defaultProps(this),
      isValidData: true,
      iconSize: '24',
      selectorsLanguages: environment?.forms?.selectorsLanguages ? environment?.forms?.selectorsLanguages : null,
      isProfessionalRole:
        JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
        JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 2,
    };
  },

  computed: {
    ...mapState('account', {
      editMode: 'editMode',
    }),

    ...mapGetters({
      accountBodyData: 'account/getBodyData',
    }),

    displayLang() {
      return this.langs.find(e => e.value === this.locale)?.label || 'Español';
    },

    getButtonLabel() {
      return this.editMode ? this.$t('common.save') : this.$t('common.edit');
    },

    langs() {
      const langs = [
        {
          value: 'es',
          label: 'Español',
        },
        {
          value: 'en',
          label: 'English',
        },
      ];

      if (this.selectorsLanguages) {
        return langs.filter(el => el.value in this.selectorsLanguages);
      }

      return langs;
    },
  },

  watch: {
    accountBodyData: {
      immediate: true,
      deep: true,
      handler(values) {
        if (!values) return;

        this.setDefaultValues(values);
      },
    },

    headerData({ isValid = false } = {}) {
      this.isValidData = isValid;
    },
  },

  methods: {
    ...mapActions({
      saveData: 'account/saveUserData',
      handleEditMode: 'account/handleEditMode',
    }),

    ...mapMutations({
      setAppLocale: 'app/setLocale',
    }),

    setDefaultValues(values) {
      for (const key in values) {
        this[key] = values[key];
      }
    },

    handleLocale(locale) {
      this.locale = locale;
    },

    handleTextareaEs(value) {
      this.bioEs = value;
    },

    handleTextareaEn(value) {
      this.bioEn = value;
    },

    handleGender(value) {
      this.gender = value;
    },

    handlePhone(_, { number = null, country = null } = {}) {
      const { dialCode } = country || {};
      let { input } = number || {};

      input = input.replaceAll(' ', '');

      this.prefix = `+${dialCode}`;
      this.phone = input.replace(this.prefix, '');
    },

    handleSave() {
      if (!this.editMode) {
        this.handleEditMode(true);
        return;
      }

      const { name, surname, dni, isValid } = this.headerData;
      if (!isValid) return;

      const successOnSave = this.saveData({
        prefix: this.prefix,
        phone: this.phone,
        locale: this.locale,
        bioEn: this.bioEn,
        bioEs: this.bioEs,
        gender: this.gender,
        name,
        surname,
        dni,
      });

      this.handleEditMode(false);

      this.$toast[successOnSave ? 'success' : 'error'](
        this.$t(successOnSave ? 'account.successEdit' : 'hiring.apiError')
      );

      this.setLocalesOnSave();
    },

    handleCancel() {
      this.setDefaultValues(this.accountBodyData);
      this.handleEditMode(false);
      this.isValidData = true;
      this.$emit('handle-cancel');
    },

    setLocalesOnSave() {
      this.setAppLocale(this.locale);
      this.$moment.locale(this.locale);
    },

    getGender(value) {
      return getGender(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.account-body {
  $--self: &;

  margin-top: rem(32);

  &__title {
    font-size: rem(14);
    font-weight: 400;
  }

  &__text {
    font-size: rem(16);
    color: rgba($color: #000000, $alpha: 0.6);
    margin-bottom: 0;
  }

  &__ul {
    list-style: none;
    padding-left: 0;
    margin-top: rem(16);
  }

  &__li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: rem(24);
    }

    &--edit-mode:not(:last-child) {
      margin-bottom: 0;
    }
  }

  &__li-content {
    width: calc(50% - 44px);
    min-width: calc(100% - 44px);

    @include breakpoint(md) {
      width: calc(50% - 44px);
      min-width: 300px;
    }
  }

  &__icon {
    margin-right: rem(16);
  }

  &__actions {
    margin-top: rem(32);
    display: flex;
    justify-content: flex-end;
  }

  &--edit-mode {
    margin-top: 0;
  }
}
</style>
