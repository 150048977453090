<template>
  <div class="account-security">
    <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0">
      {{ $t('account.security') }}
    </v-card-title>
    <!-- password -->
    <article class="account-security__group">
      <v-card-text class="px-0 py-0 text-subtitle-1 mb-2">{{ $t('message.password') }}</v-card-text>
      <div class="account-security__flex">
        <v-card-text class="px-0 py-0 text-body-2 text--secondary font-weight-regular account-security__hint">
          {{ $t('account.passwordHint') }}
        </v-card-text>
        <v-btn :to="{ name: 'AccountPasswordView' }" color="primary" outlined class="account-security__button">
          {{ $t('account.change') }}
        </v-btn>
      </div>
    </article>
    <!-- 2fa -->
    <article v-if="repHomologation" class="account-security__group mt-4">
      <v-card-text class="px-0 py-0 text-subtitle-1 mb-2">
        <span class="account-security__group-title">{{ $t('2fa.title') }}</span>
        <v-chip class="account-security__status-chip" small label :color="getTwoFaStatusColor" text-color="white">
          {{ getTwoFaStatusText }}
        </v-chip>
      </v-card-text>
      <div class="account-security__flex">
        <v-card-text class="px-0 py-0 text-subtitle-2 text--secondary font-weight-regular account-security__hint">
          {{ $t('2fa.hint') }}
        </v-card-text>
        <v-btn
          :to="!twoFaEnabled ? { name: 'Account2FAView' } : null"
          color="primary"
          outlined
          class="account-security__button"
          @click="handleTwoFaDisablePrompt"
        >
          {{ getTwoFaButtonText }}
        </v-btn>
      </div>
    </article>
    <two-fa-pincode-dialog
      :show="showPincodePrompt"
      :text="$t('2fa.disablePromptText')"
      @close="showPincodePrompt = false"
      @pincode="handlePincode"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TwoFaPincodeDialog from '@/components/2fa/TwoFaPincodeDialog';

export default {
  name: 'AccountSecurity',

  components: {
    TwoFaPincodeDialog,
  },

  data() {
    return {
      showPincodePrompt: false,
    };
  },

  computed: {
    ...mapGetters({
      twoFaEnabled: 'account/twoFaEnabled',
      repHomologation: 'app/getFeatureRepHomologationFlag',
    }),

    getTwoFaButtonText() {
      return this.twoFaEnabled ? this.$t('2fa.disable') : this.$t('2fa.setup');
    },

    getTwoFaStatusText() {
      return this.twoFaEnabled ? this.$t('common.activado') : this.$t('common.desactivado');
    },

    getTwoFaStatusColor() {
      return this.twoFaEnabled ? this.$vuetify.theme.currentTheme.success : this.$vuetify.theme.currentTheme.error;
    },
  },

  methods: {
    ...mapActions({
      disableTwoFa: 'account/disableTwoFa',
    }),

    handleTwoFaDisablePrompt() {
      this.showPincodePrompt = true;
    },

    async handlePincode({ code }) {
      const successOnSave = await this.disableTwoFa(code);

      this.showPincodePrompt = false;
      this.$toast[successOnSave ? 'success' : 'error'](this.$t(successOnSave ? '2fa.disableOk' : '2fa.disableKo'));
    },
  },
};
</script>

<style lang="scss" scoped>
.account-security {
  &__flex {
    display: block;

    @include breakpoint(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__button {
    margin-top: rem(16);
    margin-bottom: rem(16);

    @include breakpoint(md) {
      margin-top: 0;
    }
  }

  &__hint {
    width: auto;

    @include breakpoint(md) {
      width: 50%;
    }
  }

  &__group-title {
    display: block;

    @include breakpoint(md) {
      display: inline;
    }
  }

  &__status-chip {
    @include breakpoint(md) {
      margin-left: rem(16);
    }
  }
}
</style>
