<template>
  <v-card class="pa-3 mb-5 d-flex flex-column" min-height="480px">
    <v-card-text :class="signInByTokenClass">
      <div class="layout column align-center pa-5 mb-5 flex-grow-0">
        <img v-if="!showSvgLogo" :src="logo" :alt="name" width="80%" />
        <d-logo v-if="showSvgLogo" height="50" :alt="name" :slogan="false" :brand="appDisplayName.toLowerCase()" />
      </div>

      <v-form
        :class="{ ...signInByTokenClass, 'align-center': signInByTokenClass, 'justify-center': signInByTokenClass }">
        <slot />
        <div v-if="!accountSelector && !signingByToken">
          <v-btn
            rounded
            depressed
            color="primary"
            :loading="loading"
            :disabled="loading"
            class="px-5"
            data-test="login-submit"
            @click="$emit('click-btn')"
          >
            {{ btnLabel }}
          </v-btn>
        </div>
      </v-form>
    </v-card-text>

    <v-card-actions v-if="!accountSelector && !signingByToken">
      <v-spacer />
      <v-spacer />
      <div class="text-right">
        <v-btn data-test="forgot-password" text color="primary" small @click="$parent.$emit('click-link')">
          {{ linkLabel }}
        </v-btn>
        <br />
        <v-btn
          v-if="canSignup && $vuetify.breakpoint.smAndDown"
          text
          color="primary"
          small
          @click="$emit('click-second-link')"
        >
          {{ secondLinkLabel }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import DLogo from '@/components/DLogo.vue';
import environment from '@/environment';
import { mapState } from 'vuex';

export default {
  components: {
    DLogo,
  },
  props: {
    name: {
      type: String,
      required: true,
    },

    logo: {
      type: String,
      required: true,
    },

    logoClass: {
      type: String,
      required: false,
      default: '',
    },

    btnLabel: {
      type: String,
      default: '',
    },

    linkLabel: {
      type: String,
      default: '',
    },

    secondLinkLabel: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    accountSelector: {
      type: Boolean,
      default: false,
    },

    signingByToken: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      canSignup: environment.signUpAs.patient || environment.signUpAs.professional,
    };
  },
  computed: {
    ...mapState({
      appName: state => state.app.name,
      appDisplayName: state => state.app.displayName,
    }),

    showSvgLogo() {
      //TODO:Crear mejor metodo
      return /^docline/.test(this.name) || /^sinews/.test(this.name);
    },

    signInByTokenClass() {
      return {
          'd-flex': this.signingByToken,
          'flex-column': this.signingByToken,
          'flex-grow-1': this.signingByToken,
      };
    },
  },
};
</script>

<style scoped>
.login-card-link {
  text-decoration: none;
}

.v-application.ie .primary {
  background-color: #01508a !important;
  border-color: #01508a !important;
}

.v-application.ie .v-btn::before {
  background: none !important;
}
</style>
