<template>
  <div class="account-conditions">
    <v-card-title class="text-h6 font-weight-regular lighten-2 mb-1 px-0">
      {{ $t('account.terms_and_conditions') }}
    </v-card-title>
    <!-- password -->
    <article class="account-conditions__group">
      <v-card-text class="px-0 py-0 text-subtitle-1 mb-2">{{ $t('account.terms_and_conditions_documentation_title') }}</v-card-text>
      <div class="account-conditions__flex">
        <v-card-text class="px-0 py-0 text-body-2 text--secondary font-weight-regular account-conditions__hint">
          {{ $t('account.terms_and_conditions_documentation_description') }}
        </v-card-text>
        <v-btn color="primary" outlined class="account-conditions__button" @click="openToc">
          {{ $t('account.terms_and_conditions_see') }}
        </v-btn>
      </div>
    </article>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import environment from "@/environment";

export default {
  name: 'AccountConditions',
  data: function () {
    return {
      show_conditions: environment.footer?.rgpd?.showOnFirstLogin,
    };
  },
  methods: {
    ...mapActions('layout', ['handleConditionsModal', 'handleConditionsNeedsToBeAccepted']),
    openToc() {
      this.handleConditionsNeedsToBeAccepted(false);
      this.handleConditionsModal(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.account-conditions {
  &__flex {
    display: block;

    @include breakpoint(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__button {
    margin-top: rem(16);
    margin-bottom: rem(16);

    @include breakpoint(md) {
      margin-top: 0;
    }
  }

  &__hint {
    width: auto;

    @include breakpoint(md) {
      width: 50%;
    }
  }

  &__group-title {
    display: block;

    @include breakpoint(md) {
      display: inline;
    }
  }

  &__status-chip {
    @include breakpoint(md) {
      margin-left: rem(16);
    }
  }
}
</style>
