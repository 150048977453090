<template>
  <v-card class="mb-5 card-signup card-request-send">
    <v-card-text class="pt-10 pb-4">
      <v-btn depressed fab dark large color="green">
        <v-icon dark x-large>
          mdi-check-circle
        </v-icon>
      </v-btn>
      <h2 class="request-send-title pt-2 mb-3 black--text">{{ $t('signup.request_send') }}</h2>
      <h3 class="mb-2 request-send-subtitle mx-4">{{ $t('signup.request_send_text') }}</h3>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" small @click="goToLogin">
        {{ $t('common.go_home') }}
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'SignupProfessionalRequestSend',
  methods: {
    goToLogin() {
      this.$router.push('/login');
    },
  },
};
</script>
<style>
.request-send-title {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
}
.request-send-subtitle {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.card-request-send {
  width: 550px;
  min-height: 280px;
}
@media only screen and (max-width: 650px) {
  .card-request-send {
    width: 98%;
  }
}
</style>
