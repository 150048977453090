<template>
  <section class="account-page">
    <div class="account-page__inner">
      <router-view v-if="contentReady" />
      <section v-else class="py-8 px-8 white">
        <v-skeleton-loader
          type="list-item-avatar, list-item-three-line, card-heading, image, actions"
          class="profile-skeleton"
        />
      </section>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AccountMainView',

  data() {
    return {
      contentReady: false,
    };
  },

  computed: {
    ...mapState({
      userData: ({ account }) => account.data,
      authUser: ({ account }) => account.authUser,
    }),
  },

  async created() {
    !this.authUser && (await this.setAuthUserData());
    !this.userData?.id && (await this.fetchAccountData());
    this.contentReady = true;
  },

  methods: {
    ...mapActions({
      fetchAccountData: 'account/fetchAccountData',
      setAuthUserData: 'account/setAuthUserData',
    }),

    handleHeaderData(value) {
      if (!value) {
        return;
      }

      this.headerData = value;
    },
  },
};
</script>

<style lang="scss">
.account-page {
  &__inner {
    width: rem(760);
    max-width: 100%;
    margin: rem(48) auto;
  }

  &__card {
    padding: rem(24) rem(24) rem(40);
  }
}
</style>
