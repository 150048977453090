<template>
  <v-card class="pa-3 mb-5 d-flex flex-column" min-height="480px">
    <v-card-text class="d-flex flex-column flex-grow-1">
      <div class="layout column align-center pa-5 mb-5 flex-grow-0">
        <d-logo height="50" :slogan="false" :brand="appDisplayName.toLowerCase()" />
      </div>

      <div class="d-flex flex-column flex-grow-1 align-center justify-center">
        <div v-if="accountSelectorToShow">
          <LoginAccountSelector :accounts="accounts" />
        </div>
        <div v-else>
          <VProgressCircular color="primary" :size="64" indeterminate/>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import DLogo from "@/components/DLogo.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import LoginAccountSelector from "@/components/Login/LoginAccountSelector.vue";
import {getHeader, userUrl} from "@/config/config";
import {AppError, clearString} from "@/utils";
import {homePage} from "@/router/paths";
import intercom from "@/services/intercom";
import {authUserService} from "@/services/auth-user";

export default {
  components: {
    LoginAccountSelector,
    DLogo,
  },

  data: function () {
    return {
      authUser: authUserService.get(),
      accountSelector: false,
      accounts: []
    };
  },

  computed: {
    ...mapState({
      appName: state => state.app.name,
      appDisplayName: state => state.app.displayName,
      appLogoPrimary: state => state.app.images.logoPrimary,
      appMenuLogoClass: state => state.app.styles.menuLogoClass,
      appIntercomHide: state => state.app.intercom.hide,
      appIntercomId: state => state.app.intercom.id,
      appIntercomUserMaps: state => state.app.intercom.userMaps,
      appLocale: state => state.app.locale,
    }),

    accountSelectorToShow() {
      return this.accountSelector;
    }
  },

  created() {
    this.$keycloak.authenticated && this.$keycloak.token && (this.getAuthUser(this.$keycloak.token, this.$keycloak.refreshToken));
  },

  methods: {
    ...mapActions('app', ['loadConfigFromUser']),
    ...mapActions('layout', ['showAlert']),
    ...mapActions({
      setAuthUserData: 'account/setAuthUserData',
      setTwoFaStatus: 'account/setTwoFaStatus',
    }),

    ...mapMutations({
      setFeatureFlags: 'app/setFeatureFlags',
    }),
    login() {
      this.$keycloak.login();
    },

    logout() {
      this.$keycloak.logoutFn();
    },

    async getAuthUser(accessToken, refreshToken) {
      let response;

      const authUser = {
        access_token: accessToken,
        refresh_token: refreshToken,
      };

      this.authUserSave(authUser);

      try {
        response = await this.$http.get(userUrl, { headers: getHeader() });
      } catch (e) {
        throw new AppError({
          color: 'error',
          icon: 'mdi-alert-circle',
          message: 'Login error, please try again',
        });
      }

      if (response.body.estado === 1) {
        this.authUserSave(this.authUserMapper(authUser, response.body));
        this.loadConfigFromUser();
        this.loadHelpServices();

        const {
          body: { otp_enabled: twoFaStatus, features },
        } = response || {};
        await this.setAuthUserData();
        await this.setTwoFaStatus(twoFaStatus);

        this.setFeatureFlags(features);

        if (features?.marketplace) {
          await this.fetchContracts(response.body.user.email);
        }

        if (!this.checkShowAccountSelector(response.body)) {
          this.redirectAfterLogin(response.body);
        }
      } else {
        localStorage.removeItem('auth_ehealth');

        throw new AppError({
          color: 'error',
          icon: 'mdi-account-outline',
          message:
            response.body.estado === 2
              ? this.$t('errors.trial_caducado', { name: this.appDisplayName })
              : this.$t('errors.usr_dado_baja'),
        });
      }
    },

    redirectAfterLogin(userData) {
      const pathRequiresAuth = JSON.parse(window.localStorage.getItem('pathRequiresAuth'));
      if (pathRequiresAuth) {
        this.$router.push(pathRequiresAuth.fullPath);
        localStorage.removeItem('pathRequiresAuth');
      } else {
        this.goToHome(userData);
      }
    },

    checkShowAccountSelector() {
      const roles = JSON.parse(window.localStorage.getItem('auth_ehealth'))
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['roles']
        : [];
      const roleName = [
        this.$t('common.undefined'),
        this.$t('common.admin'),
        this.$t('common.professional'),
        this.$t('common.receptionist'),
        this.$t('common.rrhh'),
        this.$t('common.patient'),
      ];
      const items = [];
      roles.forEach(function(role) {
        items.push({
          title:
            role.company_name !== ''
              ? role.company_name
              : JSON.parse(window.localStorage.getItem('auth_ehealth'))['fullName'],
          subtitle: roleName[role.role_id],
          icon: role.icon,
          id: role.id,
          roleId: role.role_id,
        });
        items.push({ divider: true });
      });
      if (items.length > 0) {
        items.pop();
      }
      this.accounts = items;
      this.accountSelector = roles.length > 1;
      this.accountSelector && this.$emit('on-account-selector-visible');
      return roles.length > 1;
    },

    goToHome(data) {
      const routerPath = data.user.rol === 1 ? { name: 'Professionals' } : homePage();
      this.$router.push(routerPath);
    },

    load_i18n_message: function(folder) {
      const locale = this.$i18n.locale;
      const messages = this.$i18n.messages[locale];
      try {
        const trans_custom = require('@/core/i18n/' + folder + '/' + locale);
        const t = trans_custom.default;
        Object.keys(t).forEach(item => {
          if (typeof t[item] === 'object') {
            Object.keys(t[item]).forEach(trans => {
              if (messages[item]) {
                messages[item][trans] = t[item][trans];
              }
            });
          }
        });
        this.$i18n.setLocaleMessage(locale, messages);
      } catch (e) {
        this.$log.error(e);
      }
    },

    authUserMapper(authUser, data) {
      const isPatient = typeof data.patient !== 'undefined' && data.patient.id > 0;
      if (
        !isPatient &&
        typeof data?.empresa?.is_health_personnel !== 'undefined' &&
        data.empresa.is_health_personnel === 0
      ) {
        this.load_i18n_message('noHealthPersonnel');
      }

      const mappedUser= {
        ...authUser,
        email: data.user.email,
        name: data.user.name,
        fullName: !isPatient ? data.perfil.fullName : data.user.name + ' ' + data.user.surname,
        id: data.user.id,
        img: !isPatient ? data.perfil.img : '',
        lang: data.user.lang,
        nombreEmpresa: data?.empresa?.nombre || '',
        company_docline_api_id: data?.empresa?.docline_api_id || '',
        avatarEmpresa: data?.empresa?.avatar || '',
        logoEmpresa: data?.empresa?.logo || '',
        servicios_activos: !isPatient ? data.perfil.servicios_activos : [],
        rol: data.user.rol,
        roles: data.roles,
        user: data.user,
        trial: !isPatient ? data.perfil.trial : false,
        start_trial: !isPatient ? data.perfil.start_trial : '',
        formReserva: !isPatient ? data.perfil.formReserva : false,
        formPago: !isPatient ? data.perfil.formPago : false,
        formStepToStep: !isPatient ? data.perfil.stepToStep : false,
        permisos: !isPatient ? data.perfil.permisos : '',
        belong_to: !isPatient ? data.perfil.belong_to : 0,
        collegiateNumber:
          !isPatient && typeof data.perfil.collegiateNumber !== 'undefined' ? data.perfil.collegiateNumber : '',
        color: data?.empresa?.color || '',
        colorHexadecimal: data?.empresa?.colorHexadecimal || '',
        lastActivityTime: new Date(),
        permissions: !isPatient ? data.usuario.permissions : null,
        is_health_personnel: data?.empresa?.is_health_personnel || 1,
        is_collective: data?.empresa?.is_collective || 0,
        have_interconsultation: data?.empresa?.interconsultation === 1,
        erecipe: !isPatient && typeof data?.perfil?.erecipe !== 'undefined' ? data.perfil.erecipe : 0,
        hasSpecialtyToPrescribe:
          !isPatient && typeof data.perfil.hasSpecialtyToPrescribe !== 'undefined'
            ? data.perfil.hasSpecialtyToPrescribe
            : 0,
        company: {
          themeColorPrimary: data?.empresa?.themeColorPrimary || data?.empresa?.colorHexadecimal,
          themeColorSecondary: data?.empresa?.themeColorSecondary || data?.empresa?.colorHexadecimal,
          permissions: data?.empresa?.permissions || null,
          id: data?.empresa?.id || null,
          sharingReceptionist: data?.empresa?.sharingReceptionist,
          countryCode: data?.empresa?.country_code,
        },
        isSuperAdmin: !isPatient ? data.usuario.isSuperAdmin : false,
        payment_module: !isPatient ? data.usuario.payment_module : true,
        isPatient: isPatient,
        patient: isPatient ? data.patient : false,
        show_informed_consent: data?.empresa?.informed_consent || 0,
        stripeConfigured:
          !isPatient && typeof data.perfil.stripeConfigured !== 'undefined' ? data.perfil.stripeConfigured : 0,
        environment: data?.environment || [],
      };

      if (data.user.rol === 2) {
        mappedUser.specialtyName = data?.usuario?.professional_specialty_name
      }

      return mappedUser;
    },

    authUserSave(authUser) {
      window.localStorage.setItem('auth_ehealth', JSON.stringify(authUser));
    },

    goToSignup() {
      this.$router.push('/signup');
    },

    loadHelpServices() {
      const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));

      let user = {};
      let showIntercom = false;
      if (authUser?.id) {
        user = { ...authUser };
        const name_env = this.appName;
        const user_id = user.id;
        user.id = this.appName + '_' + user.id;
        if (
          name_env.toLowerCase() === 'uniteco' &&
          typeof user.nombreEmpresa !== 'undefined' &&
          user.nombreEmpresa !== ''
        ) {
          user.id = clearString(user.nombreEmpresa) + '_' + user_id;
        }
        showIntercom = true;
      }

      if (this.appIntercomHide !== true && showIntercom) {
        !authUser?.isPatient && intercom(this.appIntercomId, this.appIntercomUserMaps, user);
      }
    },

    loginCanceled() {
      localStorage.removeItem('auth_ehealth');
      this.accounts = [];
      this.accountSelector = false;
    },
  },
};
</script>
